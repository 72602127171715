.newspage-wrapper {
    padding-top: $header-height;

    @media #{$tablet-device} {
        font-size: 16px;
        padding-top:0;
    }

    @media #{$large-mobile} {
        font-size: 14px;
    }
    @media #{$small-mobile} {
        font-size: 12px;
    }
    @media #{$extra-small-mobile} {
        font-size: 10px;
    }
}

.newspage-banner {
    height: 90vh;

    & .newspage-content {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: flex-end;
        color: white;

        & .newspage-title {
            font-size: 6em;
            font-weight: 1000;
            line-height: 1;
            margin-bottom: 0.3em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                font-size: 2.85em;
            }
            @media #{$tablet-device} {
                font-size: 2.85em;
            }
        }

        & .newspage-date {
            font-size: 1.5em;
            margin-bottom: 3em;
        }
    }
}

.recommended-cards-container {
    & .more-like-this-area {
        width: 100%;
        padding-bottom: 7.25em;

        & .heading {
            display: flex;
            gap: 1em;
            align-items: flex-end;
            margin-bottom: 2em;

            & span {
                font-size: 2.5em;
                font-weight: 1000;
                line-height: 1;
            }

            & a {
                text-decoration: underline;
                color: $primary;
            }
        }

        & .recommended-cards {
            display: grid;
            grid-auto-flow: column;
            background-color: transparent;
            overflow-x: auto;
            overscroll-behavior-inline: contain;
            gap: 2.5em;

            & .news-card {
                width: 28em;
                max-height: 30em;
                @media #{$small-mobile,$extra-small-mobile}{
                    width: 23em;
                }
            }
        }

        & ::-webkit-scrollbar {
            display: none;
        }
    }
}

.newspage-body {
    display: flex;

    & .newspage-content {
        width: 75%;
        padding-right: 8em;
        display: flex;
        flex-direction: column;
        gap: 4em;

        @media #{$tablet-device}{
            padding-right: 4em;
        }

        & .heading {
            font-size: 3em;
            letter-spacing: 0;
            line-height: 1;
            font-weight: 400;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                font-size: 1.78em;
            }

            & .divider {
                margin-top: 0.6em;
                height: 0.04em;
            }
        }

        & .divider {
            width: 20%;
        }

        // & .content {}

        & .quotes {
            font-size: 2em;
            color: $light-text-color;
            font-style: italic;
            line-height: 1;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                font-size: 1.42em;
            }

            & .quotes-upper {
                margin-bottom: 0.8em;
                height: 0.08em;
                width: 20%;
                background-color: $primary;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    font-size: 1.71em;
                    width: 40%;
                }
            }

            & .quotes-lower {
                margin-top: 0.8em;
                height: 0.08em;
                margin-left: auto;
                width: 20%;
                background-color: $primary;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    font-size: 1.71em;
                    width: 40%;
                }
            }
        }

        & .images {
            border-radius: 1.375em;
            height: 36em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                height: 21.42em;
            }
            @media #{$tablet-device} {
                height: 32em;
            }

            & img {
                height: 100%;
                width: 100%;
            }
        }

        & .imagewithtext {
            @media #{$extraBig-device} {
                display: flex;
                justify-content: space-between;

                & .text-part {
                    width: 57.5%;
                }
            }

            @media #{$laptop-device } {
                display: flex;
                justify-content: space-between;

                & .text-part {
                    width: 57.5%;
                }
            }

            @media #{$desktop-device} {
                display: flex;
                justify-content: space-between;

                & .text-part {
                    width: 57.5%;
                }
            }

            & .image-part {
                border-radius: 1.375em;
                width: 37.5%;

                & img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            & .text-part {
                font-size: 1em;
                line-height: 1.3em;
                color: $light-text-color;
            }

            @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                & .image-part {
                    height: 11.41em;
                    width: 50%;
                }

                & .text-part {
                    text-align: justify;
                }
            }

            @media #{$tablet-device} {
                & .image-part {
                    height: 15em;
                    width: 50%;
                }

                & .text-part {
                    text-align: justify;
                }
            }
        }
    }

    & .author-card {
        width: 25%;
        background-color: $default-background;
        border-radius: 1.7em;

        @media #{$extraBig-device} {
            position: sticky;
            scroll-behavior: smooth;
            top: 10%;
        }

        @media #{$laptop-device } {
            position: sticky;
            scroll-behavior: smooth;
            top: 10%;
        }

        @media #{$desktop-device} {
            position: sticky;
            scroll-behavior: smooth;
            top: 10%;
        }

        @media #{$tablet-device} {
            position: sticky;
            scroll-behavior: smooth;
            top: 10%;
            width: auto;
        }

        padding: 2.125em 2.375em 2.8em 2.375em;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        & .profile-image {
            width: 6.5em;
            height: 6.5em;
            border-radius: 100%;
            margin-bottom: 1.4em;

            & img {
                object-fit: cover;
                object-position: center;
                height: 100%;
                width: 100%;
                border-radius: 100%;
            }
        }

        & .title {
            font-size: 0.93em;
            line-height: 1;
            margin-bottom: 0.5em;
            letter-spacing: 0.28em;
        }

        & .author {
            font-size: 1.875em;
            margin-bottom: 0.6em;
            color: $light-text-color;
            line-height: 1;
            font-style: italic;
            text-align: center;
        }

        & .divider {
            height: 0.08em;
        }

        & .share-msg {
            margin-top: 1.2em;
            font-size: 1em;
            text-align: center;
            margin-bottom: 1.3em;
        }

        & .social {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            & .social-icon {
                display: flex;

                & svg {
                    cursor: pointer;
                    border-radius: 100%;
                    width: 1.5em;
                    height: 1.5em;
                    transition: all 0.5s 0s ease;
                }
            }

            & :hover {
                & svg {
                    fill: $primary;
                }
            }
        }
    }

    @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
        flex-direction: column;

        & .newspage-content {
            width: 100%;
            padding-right: 0em;
            padding-top: 3.64em;
        }

        & .author-card {
            width: 100%;
            margin-top: 4em;
            margin-bottom: 10.42em;
        }
    }
}
