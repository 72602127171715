.partners-page-wrapper {
    padding-top: $header-height;

    @media #{$extraBig-device} {
        font-size: 1.3em;
    }

    @media #{$laptop-device} {
        font-size: 0.9em;
    }

    @media #{$desktop-device} {
        font-size: 0.7em;
    }
}