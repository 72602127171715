.image-over-square-image-with-heading {
    & .divider-container {
        margin-top: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
            margin-top: 1em;
        }
    }

    & .bookish-card {
        padding-right: 8em;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
            padding: 0;
        }
    }
}