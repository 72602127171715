.tagline-continent-selector-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4em;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile,$tablet-device}{
        padding-top: 2.64em;
    }

    & .tagline-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;

        & .button-element {
            margin-top: 2em;

            
        }
    }
}
