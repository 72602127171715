.membership-card-row {
    & .membership-card-column {

        @media #{$tablet-device}{
            padding: 0em 5em;
        }
        @media #{$large-mobile, $small-mobile, $extra-small-mobile}{
            padding-bottom:1em;
        }
        & .membership-card-form {
            background-color: white;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding: 5em 3.4em;

            @media #{$large-mobile, $tablet-device,$small-mobile, $extra-small-mobile} {
                padding: 4em 2.5em 5.6825em 2.5em;
            }
            & .form-header {
                font-size: 2em;
                padding-bottom: 1em;
                line-height: 1;
                @media #{$large-mobile,$tablet-device,$small-mobile, $extra-small-mobile} {
                    font-size: 1.25em;
                }
            }
            & .form-body > div {
                & .input-field {
                    border: 2px solid $light-text-color;
                    border-radius: 5px;
                    font-size: 1.5em;
                    padding: 0.8em;
                    width: 100%;
                    line-height: 1;
                    color: $light-text-color;
                    @media #{$large-mobile,$tablet-device,$small-mobile, $extra-small-mobile} {
                        font-size: 1em;
                    }
                }
            }
            & .select-group {
                display: flex;
                justify-content: space-between;
                gap: 1em;
                @media #{$large-mobile,$tablet-device,$small-mobile, $extra-small-mobile} {
                    flex-direction: column;
                }
            }
            & .button-group {
                display: flex;
                // flex-direction: column;
                justify-content: space-evenly;


            }
            & .form-body-button {
                font-size: 1.5em;
                padding: 0.5em 2.5em;
                font-weight: bold;
                border-radius: 100px;
                @media #{$large-mobile,$tablet-device} {
                    font-size: 1em;
                }
                @media #{$small-mobile, $extra-small-mobile} {
                    font-size: 1em;
                }
            }
        }

        & .membership-card-action {
            background-color: white;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding: 2.5em 3.1em;
            @media #{$large-mobile,$tablet-device,$small-mobile, $extra-small-mobile} {
                padding: 1.25em 1em;
            }
            width: 100%;
            & .membership-card-action-border {
                border: 1px dotted $light-text-color;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                @media #{$large-mobile,$tablet-device,$small-mobile, $extra-small-mobile} {
                    padding: 1.5em;
                }
                & .action-header {
                    width: 100%;
                    font-size: 2em;
                    line-height: 1;
                    padding-bottom: 1em;
                    @media #{$large-mobile, $extra-small-mobile,$small-mobile,$tablet-device} {
                        font-size: 1em;
                    }
                }
                & .action-body {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & .action-body-button {
                        width: 60%;
                        font-size: 1.5em;
                        padding: 0.5em;
                        font-weight: bold;
                        border-radius: 100px;
                        @media #{$large-mobile, $extra-small-mobile,$small-mobile,$tablet-device} {
                            width: 100%;
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}

.tab-box-container {
    & .sub-container {
        padding-top: 2em;
        padding-bottom: 1.5em;
        position: relative;
        color: white;

        @media #{$tablet-device} {
            height: 100%;
            padding: 5em 0em;
        }
        & .image-part {
            position: absolute;
            top: 0;
            overflow: hidden;
            z-index: -1;
            width: 100%;
            height: 100%;
            & .image-overlay {
                background-color: $primary;
                opacity: 0.7;
                z-index: -2;
                width: 100%;
                height: 100%;
            }
            & .bg-image {
                z-index: -3;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                & img {
                    transform: scale(2);
                }
            }
        }
        & .sub-container-part {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media #{$tablet-device} {
                gap: 1em;
            }
            & .sub-container-title {
                font-size: 5em;
                line-height: 1.2;
                @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                    font-size: 4.28em;
                    text-shadow: 0px 3px 6px #00000029;
                }
                @media #{$tablet-device} {
                    // font-size: 2.75esm;
                    text-shadow: 0px 3px 6px #00000029;
                }
            }
            & .sub-container-subtitle {
                font-size: 1.5em;
                line-height: 1;
                padding-bottom: 1em;
                @media #{$large-mobile,$small-mobile, $extra-small-mobile,$tablet-device} {
                    font-size: 1.25em;
                }
                @media #{$tablet-device} {
                    font-size: 1.5em;
                }
            }
            & .sub-container-content {
                padding: 1.2em 0em;
                color: white;
                @media #{$large-mobile} {
                    font-size: 14px;
                }
                @media #{$small-mobile} {
                    font-size: 12px;
                }
                @media #{$extra-small-mobile} {
                    font-size: 10px;
                }
                @media #{$tablet-device} {
                    font-size: 20px;
                }
            }
            & .white-button {
                border-radius: 0px;
            }
        }
    }
}
