.reflection-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    transform-style: preserve-3d;
    perspective: 1000px;
    height: 100%;
    width: 100%;
    border-radius: inherit;

    .reflection-content {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        transform: rotateX(0) rotateY(0);
        pointer-events: none;
        transition: 300ms linear transform;
        border-radius: inherit;
        overflow: hidden;

        &.outlined {
            outline: $image-border-outline solid $white;
        }

        &:before {
            content: '';
            position: absolute;
            width: 200%;
            height: 200%;
            left: -50%;
            top: -50%;
            background: linear-gradient(rgba(199, 199, 199, 0.2), rgba(0, 0, 0, 0.2));
        }
    }

    .reflection-grid-cell {
        position: absolute;
        z-index: 2;
        width: 10%;
        height: 10%;
    }

    @for $r from 1 to 11 {
        @for $c from 1 to 11 {
            .reflection-grid-cell-#{( ($r*10) + $c - 10)} {
                top: ($r*10%)-10%;
                left: ($c*10%)-10%;
            }

            .reflection-grid-cell-#{( ($r*10) + $c - 10)}:hover~.reflection-content {
                transform: rotateX((($r*-5)+25deg)/2) rotateY((-25deg+($c*5))/2);

                &:before {
                    transform: translateY(25-(5%*$r)/2);
                }
            }
        }
    }
}