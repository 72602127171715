.news-card {
    cursor: pointer;
    border-bottom: 2px solid $primary;

    & .news-card-image {
        width: 100%;
        height: 15em;
        object-fit: cover;
        object-position: center;
    }
    & .big-news-card-image {
        height: 30em;

        // @media #{$tablet-device}{
        //     height : 25
        // }
    }

    & .small-heading-content {
        padding: 1em 0em;
        & .heading {
            color: $primary;
        }
        & .rating-part {
            display: flex;
            justify-content: space-between;
            padding: 1em 0em;

            & .stars {
                display: flex;
                font-size: 1em;
                color: $primary;
                gap: 0.5em;
            }
            & .icon-part {
                display: flex;
                align-items: flex-end;
                gap: 0.8em;
                
                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                    display:none;
                }

                & .blank {
                    display: flex;
                    position: relative;
                    align-items: center;
                    & span {
                        font-size: 0.5em;
                        line-height: 1em;
                        /* width: 100%; */
                        color: white;
                        position: absolute;
                        transform: translateX(6px);
                    }
                }
                & .info {
                    // @media #{$large-mobile, $tablet-device} {
                    width: 2em;
                    height: 2em;
                    // }
                }
            }
            & .icon-part-sm {
                display: flex;
                align-items: flex-end;
                gap: 0.8em;

                & .blank {
                    display: flex;
                    position: relative;
                    align-items: center;
                    & span {
                        font-size: 0.5em;
                        line-height: 1em;
                        /* width: 100%; */
                        color: white;
                        position: absolute;
                        transform: translateX(6px);
                    }
                }
                & .info {
                    // @media #{$large-mobile, $tablet-device} {
                    width: 2em;
                    height: 2em;
                    // }
                }
            }
        }
    }

    & .heading-content {
        padding: 1.5em 0em 3em 0em;
        gap: 4em;
        display: flex;
        align-items: center;

        @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
            gap: 1em;
            flex-direction: column;
        }

        & .heading-part {
            width: 100%;

            & .heading {
                letter-spacing: 0.02em;
                font-size: 5em;
                /* width: 6em; */
                font-weight: 900;
                line-height: 1em;
                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
                    font-size: 2.14em;
                }
            }
            & .rating-part {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & .title {
                    font-size: 1em;
                    line-height: 3em;
                    color: $primary;
                }

                & .stars {
                    display: flex;
                    gap: 0.5em;
                    font-size: 1em;
                    color: $primary;
                }
            }
        }
        & .content-part {
            width: 100%;
            display: block;
            @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                display: none;
            }
        }
        & .content-part-sm {
            display: none; 
            @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                display: flex;
                align-items: center;
                gap: 3em;
            }
        }

        & .icon-part {
            display: flex;
            align-items: flex-end;
            height: 6em;
            gap: 0.8em;
            @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
                height: auto;
                display:none;
            }
            & .blank {
                display: flex;
                position: relative;
                align-items: center;
                & span {
                    font-size: 0.5em;
                    line-height: 1em;
                    /* width: 100%; */
                    color: white;
                    position: absolute;
                    transform: translateX(6px);
                }
            }

            & .info {
                // @media #{$large-mobile, $tablet-device} {
                width: 2em;
                height: 2em;
                // }
            }
        }
        & .icon-part-sm {
            display: flex;
            align-items: flex-end;
            // height: 6em;
            gap: 0.8em;
            & .blank {
                display: flex;
                position: relative;
                align-items: center;
                & span {
                    font-size: 0.5em;
                    line-height: 1em;
                    /* width: 100%; */
                    color: white;
                    position: absolute;
                    transform: translateX(6px);
                }
            }

            & .info {
                // @media #{$large-mobile, $tablet-device} {
                width: 2em;
                height: 2em;
                // }
            }
        }
    }

    & .news-card-content {
        padding: 1.5em 0em 3em 0em;
        gap: 4em;
        // display: flex;
        align-items: center;

        & .news-card-date {
            padding-bottom: 0.5em;
            font-size: 0.75em;
            line-height: 1;
        }

        & .news-card-title {
            font-size: 1.5em;
            line-height: 1;
        }
    }
}

.newsroom-container-1 {
    & .in-newsroom-container-1 {
        @media #{$extraBig-device} {
            height: 40em;
        }
        @media #{$laptop-device} {
            height: 40em;
        }
        @media #{$desktop-device} {
            height: 40em;
        }
        @media #{$tablet-device} {
            height: 40em;
        }
        @media #{$large-mobile} {
            min-height: 40em;
        }
        @media #{$small-mobile, $extra-small-mobile} {
            min-height: 40em;
        }

        & .highlighted-news-card {
            width: 75%;
            margin-right: 1em;
        }
        & .other-news-card {
            width: 25%;
            overflow-y: scroll;
            & img {
                cursor: pointer;
                height: 13em;
                width: 100%;
                object-fit: cover;
                object-position: center;
                margin-bottom: 1em;
            }
        }
        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            flex-direction: column;
            justify-content: flex-start;
            & .highlighted-news-card {
                width: 100%;
                margin-right: 0;
                & .news-card-title {
                    font-size: 1.2em;
                }
            }
            & .big-news-card-image {
                height: 15em;
            }
            & .other-news-card {
                width: 100%;
                display: grid;
                grid-auto-flow: column;
                gap: 0.5em;
                overflow-y: none;
                overflow-x: scroll;
                scroll-behavior: auto;
                & img {
                    height: 7em;
                    width: 28vw;
                    margin-bottom: 0em;
                    margin-top: 1em;
                }
            }
        }
        @media #{$tablet-device} {
            flex-direction: column;
            justify-content: flex-start;
            & .highlighted-news-card {
                width: 100%;
                margin-right: 0;
                & .news-card-title {
                    font-size: 1.2em;
                }
            }
            & .big-news-card-image {
                height: 20em;
            }
            & .other-news-card {
                width: 100%;
                display: grid;
                grid-auto-flow: column;
                gap: 0.5em;
                overflow-y: none;
                overflow-x: scroll;
                scroll-behavior: auto;
                & img {
                    height: 7em;
                    width: 28vw;
                    margin-bottom: 0em;
                    margin-top: 1em;
                }
            }
        }
        & .other-news-card::-webkit-scrollbar {
            width: 0;
        }
    }
}

.newsroom-container-2 {
    padding: 3em 0em;
    @media #{$large-mobile,$small-mobile, $extra-small-mobile,$tablet-device} {
        padding: 0;
        padding-bottom: 3em;
    }
}
