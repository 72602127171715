.pricing-container {
    & .table-wrapper {
        justify-content: space-between;

        @media #{$tablet-device}{
            padding: 0em 5em;
        }

        & .card-wrapper {
            margin: 4em 0em;
            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                margin: 2em 0em;
            }

            & .each-card {
                background-color: $default-background;
                border-radius: 1.5em;
                overflow: hidden;
                padding: 0;
                transition: $transition-base;
                box-shadow: $default-shadow;

                & .bold {
                    font-weight: 900;
                }

                & .black {
                    color: $black;
                }

                & .big {
                    font-size: 1.3em;
                    @media #{$large-mobile, $tablet-device}{
                        font-size: 1em;
                    }
                }

                & .table-header {
                    background-color: $black;
                    transition: $transition-base;
                    padding: 1em 3em;
                    color: $white;
                    font-size: 1.3em;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media #{$large-mobile, $tablet-device}{
                        font-size: 1.4285em;
                    }
                }

                & .table-content {
                    padding: 1em 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;
                    color: $light-text-color;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        padding: 2em 0;
                        gap: 2em;
                    }

                    & .item-divider {
                        width: 100%;
                        height: $thin-divider;
                        background-color: rgba($light-text-color, 0.1);
                    }

                    & .table-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:last-child {
                            height: 4em;

                            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                                height:auto;
                            }
                        }
                    }
                }

                &:hover {
                    & .table-header {
                        background-color: $primary;
                    }

                    box-shadow: $deep-shadow;
                }
            }

            & .adjective-tag-holder {
                height: 0;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                & .adjective-tag {
                    position: absolute;
                    text-transform: uppercase;
                    z-index: 1;
                    font-size: 0.8em;
                    padding: 0.2em 2em;
                    background-color: $primary;
                    border-radius: 2em;
                    color: $white;

                    // @media #{$large-mobile}{
                    //     width: 75%;
                    // }
                }
            }
        }

    }
}