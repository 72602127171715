.subscribecomponent {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;
    & .title {
        font-size: 1em;
        margin-left: 0.6em;
        font-weight: 1000;
    }
    & .subscribe-form {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5em;

        @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
            flex-direction: column;
            width: 100%;
        }

        & input {
            width: 25em;
            border: 0;
            border-radius: $button-border-radius;
            outline: $medium-thin-border solid $default-black;
            padding-left: 1em;
            font-weight: 300;
            padding: 0.3125em 0px 0.3125em 1em;

            @media #{$tablet-device}{
                width: 100%;
            }
            &::placeholder {
                font-size: 1em;
                font-weight: 500;
                font-style: italic;
                color: $light-text-color;
            }
        }
    }
}
