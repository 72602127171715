.image-top-center-aligned-card-component {
    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
        padding: 2.64em;
        padding-bottom: 0;
    }
    & .image-part {
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            font-size: 8px;
        }
        @media #{$tablet-device} {
            font-size: 8px;
            height:70em;
        }
        // @media #{$small-mobile} {
        //     font-size: 7px;
        // }
        width: 100%;
        height: 29.24em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .top-half-bg,
        & .bottom-half-bg {
            width: 100%;
            height: 50%;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                border-radius:1.57em 
            }
        }

        & .image-holder {
            height: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            & .centered-aligned-image {
                position: absolute;
                width: 48.74em;
                height: 29.24em;
                border-radius: $image-border-radius;
                flex-shrink: 0;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                    border-radius: 1.57em;
                    width: 100%;
                }
                @media #{$tablet-device} {
                    border-radius: 1.375em;
                    width: 100%;
                    height:70em;
                }

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    & .center-aligned-card-part {
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            font-size: 14px;
            padding-bottom: 0;
            padding: 0;
        }
    }
}
