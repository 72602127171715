.home-banner-section {
    height: calc(100vh - $header-height);

    & .swiper-container {
        height: 100%;
        width: 100%;

        & .swiper-slide {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            & .swiper-background-image {
                width: 100%;
                height: 100%;
                // overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
                object-position: center;
            }

            &>.container {
                position: absolute;
                transform: translateY(-4em);

                display: flex;
                justify-content: space-between;

                @media #{$large-mobile, $small-mobile, $extra-small-mobile, $tablet-device}{
                    transform:translateY(0em) ;
                    bottom: 8.42em;
                    // padding: 0em 2.3125em;
                }

                & .banner-card {
                    height: auto;
                    width: 40em;
                    padding: 2em 3em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0.3em;
                    background-color: $white;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                        width:100%;
                        padding:2.85em 1.5em 1.78em 1.9285em;
                        gap:0.78em;
                    }
                }
            }
        }

    }

    & #banner-pagination-holder {
        height: 0;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
            justify-content: center;
        }

        & .swiper-pagination {
            position: absolute;
            height: 2em;
            z-index: 2;
            transform: translateY(-4em);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.6em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                transform: translateY(0);
                bottom:27.14em;
            }
            & .swiper-pagination-bullet {
                cursor: pointer;
                height: 0.5em;  
                width: 0.5em;
                background-color: rgba($color: $white, $alpha: 0.75);
                border-radius: 50%;
                transition: $transition-base;
                box-shadow: $default-shadow;

                &:hover {
                    background-color: rgba($color: $white, $alpha: 0.90);
                    transform: scale(1.25);
                }

                &.swiper-pagination-bullet-active {
                    background-color: rgba($color: $white, $alpha: 1.0);
                    transform: scale(1.5);

                    &:hover {
                        background-color: rgba($color: $white, $alpha: 1.0);
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
}

.home-image-bg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.de{
    border: 2px solid red;
}