.brands-container {
    background-color: $default-background;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile,$tablet-device}{
        gap: 5em;
        overflow-x: scroll;
        width: max-content;
    }
    & svg {
        min-height: 8em;
        min-width: 8em;
        transition: $transition-base;
        opacity: 0.85;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
}

.our-partners-section{
    padding-bottom: 9.6em;
}