.hamburger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background-color: rgba($color: $white, $alpha: 0);
    & svg {
        height: $header-icon-height;
        transition: $transition-base;
        line {
            stroke: $default-black;
            stroke-width: $medium-stroke !important;
            transition: $transition-base;
        }
        & #middle-bar {
            transform: translateX(40%) scaleX(60%);
            transition: $transition-base;
        }
    }
    &.close {
        & svg {
            transform: rotate(90deg);
            & #top-bar {
                transform: rotate(45deg);
                transform-origin: 10% 35%;
                transition: $transition-base;
            }
            & #bottom-bar {
                transform: rotate(-45deg);
                transform-origin: 13% 64%;
                transition: $transition-base;
            }
            & #middle-bar {
                transform: translateX(-100%);
            }
        }
    }
    &:hover {
        & svg {
            & #middle-bar {
                transform: scaleX(100%);
            }
        }
        &.close {
            & svg {
                & #middle-bar {
                    transform: translateX(-100%);
                }
            }
        }
    }
}