.icon-button {
    font-size: 1em;
    background-color: rgba($white, 0);
    padding: 0;
    border: 0;
    border-radius: 0%;
    stroke: none;
    transition: $transition-base;
    font-weight: 500;
    opacity: 0.6;

    & svg {
        width: 1em;
    }

    &:hover {
        background-color: rgba($white, 0);
        border: 0;
        opacity: 1;
    }

    &:focus {
        outline: 0;
        box-shadow: none;

        &:hover {
            opacity: 1;
        }
    }
}