.card-over-ribbon-image-container {
    height: 31.24em;
    width: 100%;
    color: $white;

    & .image-part {
        height: 100%;
        width: 100%;
    }

    & .ribbon-card-holder {
        height: 0%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        & .ribbon-card {
            position: absolute;
            width: max-content;
            height: 100%;
            z-index: 1;
            margin-top: -35em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1em;

            @media #{ $large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
                width: 100%;
            }

            & .title {
                font-size: 1.3em;
                letter-spacing: 0.01em;
                color: $white;

                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                    font-size: 1em;
                    font-weight: normal;
                }
            }

            & .subtitle {
                font-weight: 1000;
                font-size: 4em;
                line-height: 1em;

                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                    font-size: 2.5em;
                }
            }

            & .card-button {
                margin-top: 1em;

                & button {
                    font-size: 1.2em;
                    font-weight: 700;
                    border: 2px solid $white;
                }
            }
        }
    }
}