.join-page-wrapper {

    padding-top: $header-height;

    & .join-page {
        height: calc(100vh - $header-height);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .banner-background {
            height: 100%;
            width: 100%;
            flex-shrink: 0;
            overflow: hidden;
            position: relative;

            & .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba($color: $black, $alpha: 0.8);
            }
        }

        & .title-tabs-part {
            // position: relative;
            height: 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & .page-title-container {
                position: absolute;
                bottom: 0;
                transform: translateY(-4em);
                z-index: 1;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    transform: translateY(-8.25em);
                    padding-right: 2.64em;
                }

                & .page-title {
                    color: $white;
                    display: inline-block;
                    font-size: 5em;
                    font-weight: 1000;
                    line-height: 1em;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                        font-size: 2.85em;
                    }
                }

                & .page-subtitle {
                    color: $white;
                    font-size: 1.5em;
                    text-transform: uppercase;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                        font-size: 1.42em;
                    }
                }
            }
        }
    }

    .what-you-have-seen {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);

        & .container {
            & .title {
                padding-top: 1.5em;
                padding-bottom: 1.5em;
                font-size: 4.5em;
                font-weight: 1000;
                line-height: 1em;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    padding-top: 1.68em;
                    padding-bottom: 1.8em;
                    font-size: 1.5625em;
                }
            }
        }
    }

    .image-background {
        height: 35em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media #{$large-mobile, $small-mobile,$extra-small-mobile, $extra-small-mobile} {
            height: 21em;
        }

        & .fixed-container {
            position: relative;
            width: 100%;


            & .fixed-image {
                position: absolute;
                top: -20em;
                right: 0;
                z-index: 3;
                width: 80%;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    width: 100%;
                    // width:20em;
                    top: -6em;
                }

                // transform: translateY(-24em) translateX(-29em);
                // height: 42em;
            }
        }

        & .banner-background {
            height: 35em;
            flex-shrink: 0;
            width: 100%;
            overflow: hidden;

            @media #{$large-mobile, $small-mobile,$extra-small-mobile, $extra-small-mobile} {
                height: 21em;
            }
            @media #{$tablet-device} {
                height: 30em;
            }

            & img {
                background-color: $black;
                width: 100%;
                height: 100%;
                opacity: 0.08;
                object-fit: cover;
            }
        }

        & .title-tabs-part {
            position: relative;

            & .page-title-container {
                height: 0;
                transform: translateY(-3.36em);
                flex-direction: column;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                & .page-title {
                    font-size: 2em;
                    color: $primary;
                    font-weight: 900;
                    margin-bottom: 1em;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                        font-size: 1.25em;
                        text-align: center;
                        line-height: 20px;
                    }
                }

                @media #{$large-mobile, $small-mobile, $extra-small-mobile} {
                    transform: translateY(-2.3em);
                }
            }
        }
    }

    .ims-society-membership {
        & .containers {
            @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                padding: 0;
            }

            & .title {
                text-align: center;
                padding: 4em 0em;
                letter-spacing: 0.6em;

        
                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    // padding: 2.25em 0em 1.5em 0em;
                    font-size: 0.875em;
                    letter-spacing: 0.2625em;
                    padding: 3.2142em 0;
                }
            }

            & .section-1 {
                height: 15em;
                overflow: hidden;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    height: 10.125em;
                }

                & .banner-image-container {
                    height: 100%;
                    width: 100%;
                }
            }

            & .section-2 {
                width: 100%;
                margin-top: 0.5em !important;
                gap: 0.5em;


                @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 1px;
                    margin-top: 1px !important;
                }

                & .sub-container {
                    background-color: $primary;
                    // height: 15em;
                    padding: 2em;
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                        padding: 0;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        position: relative;
                        height: 8.625em;
                    }

                    & .box-button {
                        display: flex;
                        flex-direction: row-reverse;
                        position: absolute;
                        bottom: -1em;
                        border: 1px solid $primary;
                        z-index: 3;

                        & button {
                            font-size: 1em;
                            font-weight: 800;
                            padding-top: 0.2em;
                            padding-bottom: 0.2em;
                            border-radius: 0%;
                            transition: $transition-base;
                            z-index: 3;
                        }

                        @media #{$large-mobile} {
                            & button {
                                padding: 0.2em 3.5em;
                            }
                        }

                        @media #{$small-mobile, $extra-small-mobile} {
                            & button {
                                padding: 0.2em 2.5em;
                            }
                        }

                        &:hover {
                            & button {
                                background-color: $primary;
                                color: $white;
                            }
                        }
                    }

                    & .tab-box-heading {
                        font-size: 5em;
                        color: $white;
                        line-height: normal;
                        @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                            font-size: 2.125em;
                        }
                    }

                    & .tab-box-sub-heading {
                        font-size: 1.5em;
                        color: $white;
                        font-weight: 300;
                        @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                            font-size: 1em;
                        }
                    }

                    & .divider {
                        border: 0.07em solid $white;
                        background: $white;
                        width: 6em;
                        @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                            width: 40%;
                        }

                        // margin-top: 0.8em;
                    }

                    & .tab-box-price {
                        font-size: 0.8em;
                        line-height: 2.8em;
                        @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
                            font-size: 0.5em;
                            line-height: 5em;
                            color: white;
                        }
                    }
                }

                & .sub-container:hover {
                    background-color: white;

                    & .tab-box-heading {
                        color: $primary;
                    }

                    & .tab-box-sub-heading {
                        color: $primary;
                    }

                    & .divider {
                        border: 0.07em solid $primary;
                        background: $primary;
                    }
                }
            }
        }
    }

    @media #{$extraBig-device} {
        font-size: 1.3em;
    }

    @media #{$laptop-device} {
        font-size: 0.9em;
    }

    @media #{$desktop-device} {
        font-size: 0.7em;
    }
}