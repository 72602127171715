.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    bottom: 0;
    width: 100vw;
    margin: auto;
    overflow-y: scroll;

    display: flex;
    justify-content: center;
    // align-items: center;
    & #modal-bg {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;

        /* slightly transparent fallback */
        background-color: rgba(255, 255, 255, .9);
        /* if backdrop support: very transparent and blurred */
        // @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        //     background-color: rgba(255, 255, 255, .3);
        //     -webkit-backdrop-filter: blur(0.8em);
        //     backdrop-filter: blur(0.8em);
        // }
    }
    
    & .image-content-area {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        height: max-content;
        width: 70vw;
        overflow-x: hidden;
        background-color: $white;
        cursor: default;
        border-radius: 1.3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 4.5em;
        transition: $transition-base;
        margin-top: 6em;
        margin-bottom: 6em;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            font-size: 14px;
            width: 80vw;
            padding: 1.5em;
            padding-top: 4em;
        }

        & .close-button {
            position: absolute;
            top: 1.5em;
            right: 1.5em;
            cursor: pointer;

            & svg {
                pointer-events: none;
                height: 1.5em;
                fill: $black;
                transition: $transition-base;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    height: 1em;
                }
            }

            &:hover {
                & svg {
                    fill: $red;
                }
            }
        }

        & .image-details-area {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 3em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                gap: 2.5em;
            }
            
            & .image {
                height: 20em;
                width: 25.8em;
                border-radius: 1.3em;
                overflow: hidden;
            }
            
            & .details-buttons {
                height: 20em;
                flex-grow: 1;
                color: $light-text-color;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 1em;
                    height: fit-content;
                    & .details{
                        margin-bottom: 1em;
                    }
                }
                & .details{
                    height: fit-content;
                    & .modalcard-title {
                        font-weight: 1000;
                        font-size: 2.5em;
                        color: $black;
                        line-height: 1.2em;
                    }
    
                    & .card-type {
                        font-size: 1.8em;
                        line-height: 2;
                    }
                    
                    & .features {
                        display: flex;
                        gap: 2em;
                        // border: 2px solid red;
                        font-size: 1.25em;
                        & .features-content{
                            display: flex;
                            align-items: flex-end;
                            gap: 1em;
                            & .icon{
                                line-height: 1;
                                display: flex;
    
                                & svg{
                                    fill: $light-text-color;
                                    height: 1em;
                                    width: 1.5em;
                                }
                            }
                            & .text{
                                line-height: 1;
                            }
                        }
                    }
                    & .card-description{
                        font-size: 1.25em;
                        display: flex;
                        gap: 1em;
                        margin-top: 1em;
                        & .icon{
                            line-height: 1;
                            display: flex;
                            font-size: 1.3em;

                            & svg{
                                fill: $light-text-color;
                                height: 1em;
                                width: 1em;
                            }
                        }
                        & .text{
                            line-height: 1;
                        }
                    }
                }
            }

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                flex-direction: column;
                & .image{
                    height: 17em;
                    width: 100%;
                }
                & .details-buttons {
                    & .details{
                        & .modalcard-title {
                            font-size: 2em;
                        }
                        & .card-type {
                            font-size: 1.4em;
                        }
                        & .features {
                            gap: 1em;
                            font-size: 1em;
                            flex-direction: column;
                        }
                        & .card-description{
                            font-size: 1em;
                        }
                    }
                }
            }

        }
        & .more-like-this-area{
            margin-top: 6.5em;
            width: 100%;
            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                margin-top: 3.7em;
                margin-bottom: 2em;
            }
            & .heading{
                display: flex;
                gap: 1em;
                align-items: flex-end;
                margin-bottom: 2em;
                & span{
                    font-size: 2.5em;
                    font-weight: 1000;
                    line-height: 1;
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                        font-size: 2em;
                    }
                }
                & a{
                    text-decoration: underline;
                    color: $primary;
                }

            }
            & .recommended-cards{
                display: grid;
                grid-auto-flow: column;
                background-color: transparent;
                padding-top: 1.5em;
                margin-right: -4.5em;
                margin-left: -4.5em;
                padding-left: 4.5em;
                padding-right: 4.5em;
                overflow-x: auto;
                gap: 2.5em;
                // overscroll-behavior-inline: contain;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    padding-top: 1em;
                    gap: 1em;
                }
                .modal-cards{
                    display: inline-block;
                    width: 18.75em;
                    height: 20em;
                    cursor: pointer;
                    // transition: $transition-base;
                    border-radius: 1.3em;

                    // &:hover {
                    //     box-shadow: $default-shadow;
                    // }
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        height: 13em;
                        width: 16em;
                    }
                    & .modal-card-filter{
                        position: absolute;
                        width: 100%;
                        height: 75%;
    
                        & .card-shadow {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            right: 0px;
                            height: 100%;
                            background: radial-gradient(circle, rgba(181, 181, 181, 0) 23%, rgba(44, 44, 44, 0.39289219105610995) 80%);
                            border-radius: 1.3em;
    
                            & .download-btn {
                                width: 1.7em;
                                // border: 2px solid green;
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                margin: 1em 1.7em;
                                cursor: pointer;
                                & .download-btn-cover{
                                    position: absolute;
                                    inset: 0;
                                    z-index: 2;
                                }
                                & svg{
                                    z-index: 1;
                                    width: 100%;
                                }
                            }
                            & .grid-figure-crown{
                                position: absolute;
                                display: flex;
                                bottom: 100%;
                                left: 8%;
                                & svg{
                                    height: 1.3em;
                                }
                            }
                        }
                    }
                    & .recommended-card-image{
                        z-index: 1;
                        width: 100%;
                        height: 75%;
                        border-radius: 1.25em;
                        // overflow: hidden;
                        & img{
                            border-radius: 1.25em;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;
                            width: 100%;
                            height: 100%;
                        }

                    }
                    & .recommended-card-content{
                        border-radius: 1.25em;
                        padding: 1em 1.3em;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0.5em;
                        & .content-title{
                            font-weight: bold;
                            line-height: 1;
                        }
                        & .content-subtitle{
                            line-height: 1;
                        }

                    }
                }
            }
            & ::-webkit-scrollbar {
                display: none;
            }
        }

    }

}
.modal-container::-webkit-scrollbar {
    display: none;
}