.image-over-thin-image {
    font-size: 1em;
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    & .bottom-image {
        align-self: flex-start;
        width: 20em;
        height: 40.97em;
        // overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$large-mobile ,$small-mobile, $extra-small-mobile} {
            width: 62.38%;
            height: 31.5em;
        }
        @media #{$tablet-device } {
            width: 62.38%;
            height: 47em;
        }

        & .image-overlay {
            position: absolute;
            height: inherit;
            width: inherit;
            background-color: $color-overlay;
            opacity: 0.75;

            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                width: 100%;
            }
            @media #{$tablet-device} {
                width: 100%;
            }
        }
    }

    & .top-image {
        position: absolute;
        width: 22.12em;
        height: 31.54em;
        transform: translateX(calc(22.12em / 1.88));
        border-radius: $image-border-radius;
        z-index: 1;

        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            width: 17.07em;
            height: 24.21em;
            right: 2.6428em;
            transform: translateX(0);
        }
        @media #{$tablet-device} {
            width: 24em;
            height: 34em;
            right: 0em;
            transform: translateX(0);
        }

        & .image-wrapper {
            height: 100%;
            width: 100%;
            border-radius: $image-border-radius;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: inherit;
            }
        }
    }

    &.inverse {
        justify-content: flex-end;

        & .bottom-image {
            align-self: flex-end;
        }

        & .top-image {
            transform: translateX(-1 * calc(22.12em / 1.88));
            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                transform: translateX(0);
                left: 2.3125em;
            }
            @media #{$tablet-device} {
                transform: translateX(0);
                left: 0;
            }
        }
    }
}

.image-over-square-image {
    font-size: 1em;
    width: 84em;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        width: 100%;
        padding-top:2.64em;
    }
    @media #{$tablet-device} {
        width: 100%;
    }

    & .bottom-image {
        align-self: flex-start;
        width: 29em;
        height: 28em;
        border-radius: $image-border-radius;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            width: 16.07em;
            height: 15.57em;
            border-radius: 1.57em;
        }
        // @media #{$small-mobile} {
        //     width: 12.07em;
        //     height: 12.57em;
        //     border-radius: 1.57em;
        // }

        & .image-overlay {
            position: absolute;
            height: inherit;
            width: inherit;
            flex-shrink: 0;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .top-image {
        position: absolute;
        width: 24em;
        height: 17em;
        transform: translateX(-22.5em) translateY(2.5em);
        z-index: 1;
        flex-shrink: 0;

        // @media #{$small-mobile} {
        //     width: 10.21em;
        //     height: 6.64em;
        // }
        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            width: 13.21em;
            height: 9.64em;
            left: 0;
            transform: translateX(0);
            border-radius: 1.57em;
        }
        @media #{$tablet-device} {
            transform: translateX(-18em);
        }

        & .image-wrapper {
            height: 100%;
            width: 100%;
            border-radius: $image-border-radius;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: inherit;
            }
        }
    }

    &.inverse {
        justify-content: flex-end;

        & .bottom-image {
            align-self: flex-end;
        }

        & .top-image {
            transform: translateX(-1 * calc(22.12em / 1.88));
        }
    }
}
