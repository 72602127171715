.footer {
    height: max-content;
    padding: 4em 0 2em 0;
    font-size: 1em;
    color: $default-black;
    background-color: $white;
    overflow: hidden;
    position: relative;
    @media #{$extraBig-device, $laptop-device, $desktop-device} {
        height: 100vh;
    }
    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        height: max-content;
        padding-bottom: 7.625em;
    }
    & .container {
        height: 100%;
    }

    & .divider {
        font-size: 1em;
        height: max-content;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .divider-line {
            position: absolute;
            height: $thicker-divider;
            width: 50%;
            right: 0;
            background-color: $default-black;
            border-radius: 2.5em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                width: 100%;
            }
        }
    }
    & .page-wrapper {
        padding-top: 1em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            align-items: center;
        }

        & .sitemap-section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                flex-direction: column;
                padding-top: 3em;
                width: 100%;
            }

            & .sitemap-part {
                font-size: 0.7em;
                padding: 0;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    width: 100%;
                    font-size: 1em;
                }
            }
        }

        & .subscribe-section {
            padding: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 1em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                flex-direction: column;
                width: 100%;    
                
            }

            & .col-6 {
                padding: 0;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    width: 100%;
                }
            }
            & .subscribecomponent {
                font-size: 0.8em;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    margin-top: 1em;
                    font-size: 1em;
                    align-items: center;
                }

                & .title {
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        font-size: 1.42em;
                    }
                }
            }
        }

        & .privacy-copyright-section {
            padding: 0;
            gap: 0;
            font-size: 1em;
            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                margin-top: 2em;
                width: 100%;
            }

            & .privacy-copyright-section-left {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                gap: 1.5em;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    align-items: center;
                }
                & .contactus {
                    font-size: 0.8em;
                    font-weight: 1000;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        display: none;
                    }
                }

                & .social-links {
                    gap: 2em;
                }
                & .copyright {
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        display: none;
                    }
                }

                & .copyright-component {
                    font-size: 0.7em;
                }
            }

            & .privacy-copyright-section-right {
                padding: 0;
                font-weight: 300;
                font-size: 0.7em;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 1.5em;
                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    font-size: 1em;
                }

                & .copyright-sm {
                    display: none;
                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                        display: flex;
                        justify-content: center;
                        width: 100%;

                    }
                }
                & .legals {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                        display: grid;
                        grid-template-columns: auto auto;
                        margin-top: 1.25em;
                        row-gap: 1em;
                    }
                    @media #{ $tablet-device} {
                        display: grid;
                        grid-template-columns: auto auto auto;
                        margin-top: 1.25em;
                        row-gap: 1em;
                    }

                    & a {
                        text-decoration: underline;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}
