.forms {
    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        padding-bottom: 2em;
    }
    & .heading {
        padding: 1em 0em;
        font-size: 4em;
        font-weight: 1000;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            font-size: 1.875em;
        }
    }

    & .box {
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            padding: 2em;
            border: 1px solid gray;
            border-radius: 10px;
            box-shadow: 0px 3px 6px grey;
        }
    }
    & .form-body {
        & .input-field {
            border: 2px solid #707070;
            border-radius: 5px;
            font-size: 1.5em;
            padding: 0.8em;
            width: 100%;
            line-height: 1;
            color: #707070;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                font-size: 1em;
            }
        }
        & .terms-condition {
            padding-left: 3em;
            display: flex;
            gap: 3em;
            justify-content: center;
            align-items: center;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                gap: 12px;
                padding-left: 1em;
            }

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                align-items: flex-start;
            }

            & input.larger {
               zoom: 1.5;
            }
            & span {
                font-size: 1.5em;
                line-height: normal;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    font-size: 1em;
                }
            }
        }
        & .button-part {
            margin: 4em 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            & .form-body-button {
                font-size: 1.5em;
                padding: 0.5em 2.5em;
                font-weight: bold;
                border-radius: 100px;

                @media #{$large-mobile,$small-mobile, $tablet-device} {
                    font-size: 14px;
                }
                @media #{$tablet-device} {
                    font-size: 16px;
                }
                @media #{$small-mobile} {
                    font-size: 12px;
                }
                @media #{$extra-small-mobile} {
                    font-size: 12px;
                }
            }
        }
    }
}
