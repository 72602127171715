.position-card-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    border: 0.02em solid $light-text-color;
    border-bottom: 1.125em solid $primary;
    border-radius: 0.6em;

    & .head-part {
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        @media #{$small-mobile, $extra-small-mobile} {
            font-size: 0.8em;
        }
        & .head-left {

            height: max-content;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            gap: 0.5em;

            & .position-title {
                @media #{$large-mobile, $tablet-device} {
                    font-size: 2em;
                }
                @media #{$small-mobile, $extra-small-mobile} {
                    font-size: 1.5em;
                }
                font-size: 2.5em;
                font-weight: 900;
                line-height: 1.125em;
                max-width: 10em;
                white-space: nowrap;
                overflow: hidden;

                & .position-title-text-holder {
                    width: max-content;
                    height: 100%;
                }
            }

            & .position-subtitle {
                font-size: 1.5em;
                font-weight: 500;
                line-height: 1em;
            }
        }

        & .head-right {
            font-size: 1.125em;
            min-width: 8em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 1em;

            & .location {
                font-weight: 900;
                line-height: 1em;
            }

            & .post-date {
                color: $light-text-color;
                line-height: 1em;
            }
        }
    }

    & .description-part {
        width: 100%;
        margin-top: 2em;
        display: flex;
        align-items: flex-start;
        gap: 2.6em;
        
        @media #{$small-mobile} {
            gap: 1.5em;
        }
        @media #{$extra-small-mobile} {
            gap: 1.5em;
        }

        & .description-left {
            & .position-icon {
                height: 10.5em;
                width: 10.5em;
                background-color: $very-light-text-color;
                display: flex;
                justify-content: center;
                align-items: center;
                
                & svg {
                    width: 5.25em;
                    fill: $light-text-color;
                }
                @media #{$large-mobile,$small-mobile, $tablet-device} {
                    height: 6em;
                    width: 6em;
                    & svg{
                        width: 3em;
                    }
                }
                @media #{$extra-small-mobile} {
                    height: 5em;
                    width: 5em;
                    & svg{
                        width: 2.5em;
                    }
                }
            }
        }

        & .description-middle {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.5em;

            & .share-link,
            & .copy-link {
                & svg {
                    width: 1.5em;
                    fill: $secondary;
                }
            }
        }

        & .description-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.6em;
            color: $light-text-color;
            font-weight: 600;

            & .position-type {
                font-size: 1em;
            }

            & .short-description {
                font-size: 0.9em;
            }
        }
    }

    & .action-part {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 2.6em;
        
        @media #{$small-mobile, $extra-small-mobile} {
            font-size: 1.2em;
        }
        & .apply-button,
        & .view-more-button {
            font-size: 1em;
            font-weight: 900;
        }
    }
}