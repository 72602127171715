.continent-selector-component {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5em;

    & .title {
        @media #{$large-mobile,$small-mobile} {
            font-size: 1em;
        }
    }
    & .continent {
        font-size: 1.05em;
        font-weight: 900;
        text-transform: uppercase;
        color: rgba($color: $light-text-color, $alpha: $opacity-high);
        transition: $transition-base;
        cursor: pointer;

        @media #{$large-mobile, $tablet-device,$small-mobile}{
            font-size: 1.28em;
        }

        &:hover {
            color: rgba($color: $black, $alpha: $opacity-base);
        }

        &.active {
            color: rgba($color: $black, $alpha: $opacity-very-high);
            transform: translateX(0.4em) scale($scale-base);
            pointer-events: none;
        }

        & > span {
            pointer-events: none;
        }
    }
    & .divider {
        width: 100%;
        height: 0.1em;
    }
}
