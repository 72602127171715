.bookish-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;

    @media #{$tablet-device} {
       width:100%;
    }

    
    & button {
        margin-top: 1em;
    }
}
