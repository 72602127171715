/*----------------------------------------*/

/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

/*-- Google Font --*/

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); */

/* Gotham Font */

@import "../fonts/Gotham/stylesheet.scss";

/*-- Common Style --*/

*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    height: 100vh;
    width: 100vw;
    font-family: $font-family-base;
    font-weight: $font-wight-base;
    overflow-x: hidden;
}

body {
    background-color: $body-color;
    color: $default-black;
    position: relative;
    outline: none;
    visibility: visible;
    line-height: 1.714;
    // overflow-x: hidden;

    @media #{$extra-small-mobile} {
        font-size: 10px !important;
    }
    @media #{$small-mobile} {
        font-size: 12px !important;
    }
    @media #{$large-mobile} {
        font-size: 14px !important;
    }
    @media #{$tablet-device} {
        font-size: 16px !important;
    }


    @supports (overflow-x: clip) {
        overflow-x: clip;
    }

    color: "";

    &[dir="rtl"] {
        text-align: right;
    }

    & *::selection {
        color: $white;
        background: $primary;
    }
}

// Managing Scroll Bar
body::-webkit-scrollbar {
    width: $scrollbar-width;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: $white;
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: $primary;
    /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    border: 0.5px solid $primary;
    /* creates padding around scroll thumb */
}

// Scrollbar for mozilla
html {
    scrollbar-color: $primary $white;
    /* thumb and track color */
    scrollbar-width: thin;
}

a,
button {
    transition: $transition-base;
    outline: 0;

    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

p:last-child {
    margin-bottom: 0;
    // text-align: center;
}

ul,
ol {
    padding: 0;
    list-style: none;
    margin: 0;
}

/* img {
    max-width: 100%;
} */

// Main Wrapper
.main-wrapper {
    background-color: #141414;
}

.nav-tabs {
    border-bottom: 0px;

    .nav-link {
        cursor: pointer;
        border: 0;
        margin-bottom: 0;
        padding: 0;

        &.active {
            background-color: transparent;
            border: 0;
        }

        &:hover {
            border: 0;
        }
    }
}

// Custom Container
.container {
    @media #{$extraBig-device} {
        max-width: 1920px;
        padding: 0 70px;
    }
    @media #{$large-mobile} {
        padding: 0em 2.6428em;
    }
    @media #{$small-mobile} {
        padding: 0em 3em;
    }
    @media #{$extra-small-mobile} {
        padding: 0em 3.7em;
    }
    // @media #{$tablet-device} {
    //     padding: 0em 2.3125em;
    // }
}

// .title {
//     @media #{$large-mobile, $tablet-device} {
//         font-size: 1em;
//         color: #707070;
//         letter-spacing: 0.3em;
//         font-weight: 600;
//     }
// }

/* Section Style */
.section,
.section-with-padding {
    width: 100%;

    .title {
        // font-size: 1em;
        color: $light-text-color;
        letter-spacing: 0.4em;
        font-weight: 600;
        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            font-size: 1em;
            color: #707070;
            letter-spacing: 0.3em;
            font-weight: 600;
            // line-height: 4.416em;
        }
    }

    .subtitle {
        @media #{$large-mobile, $small-mobile, $extra-small-mobile} {
            font-size: 2.1428em;
            line-height: 1em;
        }
        @media #{$tablet-device} {
            font-size: 2.1428em;
            line-height: 1.46em;
        }
        font-size: 3em;
        font-weight: 900;
        line-height: 1.3em;
        letter-spacing: 0.02em;
    }

    .divider {
        height: $thick-divider;
        width: $normal-divider-length;
        background-color: $primary;

        &.bottom-divider {
            width: 5em;
        }
    }

    .content {
        color: $light-text-color;
        line-height: 1.21em;
    }
}

.section-with-padding {
    padding: $section-padding;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        padding: 0em;
    }
    @media #{$tablet-device} {
        padding: 2em 0em;
    }
}

.half-devided-section {
    display: flex;
    justify-content: flex-start;
    gap: $container-gap;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        flex-direction: column;
        gap: 1em;
    }
    @media #{$tablet-device} {
        flex-direction: column;
        gap: 1em;
    }

    & > div {
        width: 50%;
        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            width: 100%;
        }
        @media #{$tablet-device} {
            width: 100%;
        }
    }

    &.container-inverse {
        flex-direction: row-reverse;
        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            flex-direction: column;
        }
        @media #{$tablet-device} {
            flex-direction: column;
        }
    }
}

.large-heading {
    font-size: 6em;
    font-weight: 900;
    line-height: 1em;
    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        font-size: 1.875em;
        font-weight: 1000;
    }
    @media #{$tablet-device} {
        font-size: 1.875em;
        font-weight: 1000;
    }
}

.odd-divided-section {
    display: flex;
    justify-content: flex-start;
    gap: $container-gap;

    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        flex-direction: column-reverse;
    }
    @media #{$tablet-device} {
        flex-direction: column-reverse;
    }

    & > div {
        &:first-child {
            width: 45.48%;
            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                width: 100%;
            }
            @media #{$tablet-device} {
                width: 100%;
            }
        }

        &:last-child {
            width: 54.52%;
            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                width: 100%;
            }
            @media #{$tablet-device} {
                width: 100%;
            }
        }
        & .button-element {
            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            @media #{$tablet-device} {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }
    }

    &.container-inverse {
        flex-direction: row-reverse;
    }
}

.white-background {
    background-color: $white;
}

.button-element {
    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 2.64em;
    }
    @media #{$tablet-device} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
/* Section Padding */

.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;

    @media #{$laptop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$desktop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    /* @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    } */
}

.section-padding-02 {
    padding-top: 100px;

    @media #{$laptop-device} {
        padding-top: 100px;
    }

    @media #{$desktop-device} {
        padding-top: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
    }
    @media #{$small-mobile} {
        padding-top: 40px;
    }
    @media #{$extra-small-mobile} {
        padding-top: 20px;
    }
}

.section-padding-03 {
    padding-bottom: 100px;

    @media #{$laptop-device} {
        padding-bottom: 100px;
    }

    @media #{$desktop-device} {
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
    @media #{$small-mobile} {
        padding-bottom: 40px;
    }
}

.section-padding-03-min {
    padding-bottom: 4em;

    @media #{$laptop-device} {
        padding-bottom: 4em;
    }

    @media #{$desktop-device} {
        padding-bottom: 4em;
    }

    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 0em;
    }
    @media #{$small-mobile} {
        padding-bottom: 0em;
    }
}

.single-page {
    min-height: calc(100vh - $header-height - $navigator-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

// No select text
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
