.home-our-networks-section {
    padding-bottom: 5em ;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1em;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
        padding-top: 4.92em;
        padding-bottom: 4.64em;
    }
    & .section-title {
        font-weight: 1000;
        font-size: 1.3em;
    }
    & .brands-part {
        background-color: $default-background;
        padding: 4em 0;
        width: 100%;
        
        overflow-x: scroll;
        
        // @media #{$large-mobile, $tablet-device}{
        // }

        @media #{$small-mobile}{
        }
    }
    .brands-part::-webkit-scrollbar{
        display: none;
    }
}