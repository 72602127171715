.opening-carousel-container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
    @media #{$large-mobile, $tablet-device} {
        font-size: 14px;
        & .heading-part{
            & .subtitle{
                font-size: 2em;
            }
            & .title{
                font-size: 1em;
            }
        }
    }
    @media #{$small-mobile,$extra-small-mobile} {
        font-size: 14px;
        & .heading-part{
            & .subtitle{
                font-size: 1.8em;
            }
            & .title{
                font-size: 0.8em;
            }
        }
    }
    
    & .heading-part {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            padding-top: 2.64em;
        }
        & .title {
            padding-left: 0.4em;
        }
    }

    & .carousal-part {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & .swiper-navigation {
            cursor: pointer;
            transition: opacity $transition-specific;
            & svg {
                height: 1.5em
            }

            &.swiper-button-disabled {
                opacity: $opacity-less;
                pointer-events: none;
            }
        }
    }
    & .carousal-part-desktop{
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            display: none;
        }
    }
    & .carousal-part-mb{
        display: none;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            display: block;
            & .swiper-system-mb{
                display: grid;
                grid-auto-flow: column;
                overflow-x: auto;
                scroll-behavior: smooth;
                font-size: 14px;
                & .card-holder{
                    margin-right: 2em;
                    // width: 70vw;
                }
                @media #{$small-mobile,$extra-small-mobile, $tablet-device, $large-mobile} {
                    font-size: 13px;
                    padding-bottom: 2.64em;
                    & .card-holder{
                        width: 82vw;
                    }
                }
                @media #{$extra-small-mobile} {
                    font-size: 12px;
                }
            }
            & ::-webkit-scrollbar{
                display: none;
            }
        }

    }
}