.sitemap-wrapper {
    font-size: 1em;
    max-width: 60em;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$tablet-device}{
        max-width: 100%;
    }

    & .row,
    & .col {
        margin: 0;
        padding: 0;
    }

    & .pagegroup {
        padding: 1em 0;

        & .group-title {
            transition: $transition-base;

            & .title {
                font-size: 1.3em;
                font-weight: 1000;

                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
                    font-size: 1em;
                }
            }
        }

        &:hover {
            & .group-title {
                color: $primary;
            }

            & a {
                color: $primary;

                &:hover {
                    color: $black;
                }
            }
        }

        & .page {
            & a {
                font-size: 1em;
                font-weight: 500;
            }
        }
    }
}