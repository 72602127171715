.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    & .modal-dialog {
        transform: translate(0, 2em) !important;
        max-width: 100% !important;
        display: flex;
        justify-content: center;
        & .modal-content {
            width: 65%;
            height: 40em;
            border: none;
            padding: 3em;
            border-radius: 1em;

            & .info-part {
                display: flex;
                gap: 2em;

                & .image-part {
                    & img {
                        height: 17em;
                        width: 23em;
                        object-fit: cover;
                        border-radius: 1em;
                    }
                }
            }
        }
    }
}
