.pagination {
    display: flex;
    justify-content: space-between;
    padding: 1.5em 0em;
    font-size: 0.8em;
    letter-spacing: 0.03em;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        flex-direction: column;
        width:100%;
        justify-content: flex-start;
        align-items: flex-start;
        gap:31px

    }

    & .displayed-per-pages {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;

        & .pages {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            & span {
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }

    }

    & .page-numbers-part {
        display: flex;
        gap: 1em;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            width: 100%;
        }

        & .page-count {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .symbol {
            // height: 2em;
            // width: 2em;
            padding: 0.5em 1em;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: $transition-base;
            // @media #{$large-mobile} {
            //     padding:0.5em 1em;
            // }
        }

        & .symbol:hover {
            background-color: $primary;
            color: $white;
        }

        & .page-numbers {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                overflow-x: scroll;
                justify-content: flex-start;
            }

            & span {
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    & .active {

        color: $white;
        background-color: rgba($primary, $opacity-base);
        border-bottom: 0.2em solid $primary;
        margin-bottom: -0.2em;
        cursor: default !important;

        &:hover {
            color: $white !important;
            background-color: rgba($primary, $opacity-base) !important;
        }
    }
}