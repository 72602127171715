.cards-carousel-wrapper {
    height: max-content;
    width: 100vw;
    overflow: hidden;
    position: relative;

    & .swiper-slide {
        width: max-content;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    & .swiper-navigation-wrapper {
        position: absolute;
        width: 100%;/* 
        top: calc(20.97em /2); */
        padding: 0em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        & .swiper-navigation {
            cursor: pointer;
            transition: opacity $transition-specific;
            & svg {
                height: 1.5em
            }
    
            &.swiper-button-disabled {
                opacity: $opacity-less;
                pointer-events: none;
            }
        }
    }
}