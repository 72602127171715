.search {
    height: calc($header-button-height + 0.1em);
    font-size: 1em;
    transition-property: all;
    transition-duration: 1s;
    position: relative;

    & form {
        width: max-content;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
        
        & input {
            position: absolute;
            z-index: 1;
            color: $default-black;
            width: 0em;
            height: 100%;
            border: 0;
            padding: 0;
            opacity: 0;
            border-radius: $button-border-radius;
            background-color: $default-background;
            transition: $transition-base;

            transform: translateX(0em);

            &::placeholder {
                font-size: 1em;
                font-weight: 300;
                font-style: italic;
            }
        }

        & .search-button {
            display: flex;
            z-index: 1;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: $white, $alpha: 0);
            border: 0;
            border-radius: 0 50% 50% 0;
            outline: 0;
            padding: 0.4em;
            margin-right: -0.15em;
            position: absolute;
            transition: $transition-base;

            & svg {
                height: 1.3em;
                transition: $transition-base;
                fill: $black;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-within {

            & input {
                width: 100%;
                width: 21em;
                padding: 0 2em 0 1em;
                border-radius: $button-border-radius;
                outline: 0;
                margin: 0;
                opacity: 1;
            }

            & .search-button {
                background-color: $primary;

                & svg {
                    fill: $white;
                }
            }

            @media #{$large-mobile} {
                & input {
                    left: 0;
                    right: 0;
                    transform: translateX(-14.75em);
                }

                & .search-button {
                    transform: translateX(6.5em);
                }
            }

            @media #{$small-mobile, $extra-small-mobile} {
                & input {
                    left: 0;
                    right: 0;
                    transform: translateX(-15.75em);
                    width: 22em;
                }

                & .search-button {
                    transform: translateX(6.5em);
                }
            }
        }
    }
}

.search-invisible {
    @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
        opacity: 0;
        transition-property: all;
        transition-duration: 1s;
    }
}