.floating-earth-button {
    position: fixed;
    bottom: 3.9em;
    right: 3.9em;
    z-index: 4;
    cursor: pointer;

    & .button-image {
        height: 4.26em;
        width: 4.26em;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: $default-shadow;

        & video {
            height: 105%;
            width: 105%;
            object-fit: cover;
            animation: tilt 120s linear infinite;
        }

        @keyframes tilt {

            0%,
            100% {
                transform: rotate(23.5deg);
            }

            50% {
                transform: rotate(-23.5deg);
            }
        }
    }

    @media #{$large-mobile, $small-mobile, $extra-small-mobile, $tablet-device} {
        bottom: 0;
        left: -50%;
        right: 0;
        width: 200%;
        height: 6em;
        border-radius: 50% 50% 0 0;
        background: rgba($white, 0.9);
        /* Old Browsers */
        background: -moz-radial-gradient(bottom, ellipse cover, rgba($white, 0.95) 0%, rgba($white, 0.95) 20%, rgba($black, 0) 70%);
        /* FF3.6+ */
        background: -webkit-gradient(radial, bottom bottom, 0px, bottom bottom, 100%, color-stop(0%, rgba($white, 0.9)), color-stop(20%, rgba($white, 0.9)), color-stop(60%, rgba($black, 0)));
        /* Chrome, Safari4+ */
        background: -webkit-radial-gradient(bottom, ellipse cover, rgba($white, 0.95) 0%, rgba($white, 0.95) 20%, rgba($black, 0) 70%);
        /* Chrome10+,Safari5.1+ */
        background: -o-radial-gradient(bottom, ellipse cover, rgba($white, 0.95) 0%, rgba($white, 0.95) 20%, rgba($black, 0) 70%);
        /* Opera 11.10+ */
        background: -ms-radial-gradient(bottom, ellipse cover, rgba($white, 0.95) 0%, rgba($white, 0.95) 20%, rgba($black, 0) 70%);
        /* IE 10+ */
        background: radial-gradient(ellipse at bottom, rgb(255 255 255) 0%, rgb(255 255 255 / 34%) 32%, rgb(0 0 0 / 0%) 55%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
        /* IE6-9 fallback on horizontal gradient */
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        & .button-image {
            pointer-events: all;
            height: 5.26em;
            width: 5.26em;
            transform: translateY(-1.5em);
        }
    }
}