.column-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    & .count {
        font-size: 4em;
        font-weight: 700;
        color: $black;
    }
    & .card-divider {
        height: $thin-divider;
        width: 4em;
        background-color: $primary;
        transform: translateY(-1em);
    }
    & .card-title {
        color: $black;
    }
    & .card-content {
        color: $light-text-color;
    }
    & .card-button {
        margin-top: 4em;

        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
            margin-top: 1em;
        }
        @media #{$tablet-device} {
            margin-top: 1em;
        }
    }
}
