.home-testimonial-section {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 4em;

    & .background-image-holder {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $black;
        z-index: -1;
    }

    &>.container {
        & .swiper-wrapper {
            & .swiper-slide {
                & .swiper-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    gap: 2em;
                    background-color: rgba($color: $black, $alpha: 0);
                    color: $white;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                        padding-top: 3.71em;
                    }
    
                    & .profile-image {
                        height: 10em;
                        width: 10em;
                        overflow: hidden;
                        border-radius: 50%;
                        flex-shrink: 0;
    
                        & img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
    
                    }
    
                    & .comment {
                        font-size: 1.5em;
                        font-weight: 700;
                        line-height: 1.3;
                        font-style: italic;
                        max-width: 60%;
                    }
    
                    & .author-name {
                        font-size: 1em;
                        font-weight: 600;
                    }
    
                    & .author-designation {
                        color: $primary;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    & #testimonial-pagination-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        & .swiper-pagination {
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.6em;

            & .swiper-pagination-bullet {
                cursor: pointer;
                height: 0.5em;
                width: 0.5em;
                background-color: $light-text-color;
                border-radius: 50%;
                transition: $transition-base;
                box-shadow: $default-shadow;

                &:hover {
                    background-color: $white;
                }

                &.swiper-pagination-bullet-active {
                    background-color: $primary;
                    transform: scale(1.5);

                    &:hover {
                        background-color: $primary;
                    }
                }
            }
        }
    }
}