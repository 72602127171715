.menu-card {
    height: max-content;

    & .menu-card-heading {
        font-size: 1.125em;
    }

    & .menu-card-tab-content {
        font-size: 0.8em;

        & .tab-column {

            & .tab-box {
                border-top: 3px solid $primary;
                min-height: 17em;
                min-width: 17em;
                cursor: pointer;
                padding-top: 4em;
                padding-left: 1.7em;
                border-bottom: 3px solid $primary;

                & .tab-box-heading {
                    font-size: 5em;
                    width: fit-content;
                    line-height: 1;
                }

                & .tab-box-subtitle {
                    font-size: 1.5em;
                    line-height: 1;
                }

                & .tab-box-price {
                    font-size: 0.8em;
                }
            }

            // tab-box
        }

        // tab -column

        & .menu-card-content {
            background-color: $default-background;

            & .image-part {
                height: 17em;
                width: 100%;
            }

            & .join-button {
                border-radius: 0;

                & span {
                    font-weight: 900;
                    font-size: 1.2em;
                }
            }

            & .menu-card-content-heading {
                font-size: 1.6em;
                line-height: 1;
            }

            & .menu-card-content-list {
                list-style: disc;
                font-size: 1.2em;
                padding: 0.3em 0;
            }
        }
    }

    & .menu-card-narrow-screen {
        display: none;
    }

    @media #{$large-mobile, $small-mobile, $extra-small-mobile, $tablet-device} {
        & .menu-card-wide-screen {
            display: none;
        }

        & .menu-card-narrow-screen {
            display: block;

            & .image-card {

                display: flex;
                flex-direction: column;
                gap: 0.5em;

                & .image-part {
                    width: 100%;
                    height: 23em;

                    & .parallax-image-component {
                        & .background-image {
                            z-index: 0;
                        }
                    }
                }

                & .tab-list-component {
                    width: 100%;
                    height: 37em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5em;

                    & .nav-part {
                        height: 100%;
                        width: 70em;

                        @media #{$tablet-device} {
                            width: 45em;
                        }
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        & .tab {
                            width: 100%;
                            height: 12em;
                            background-color: $primary;
                            color: $white  !important;

                            & .tab-inner {
                                margin: 0.2em 0;
                                padding: 0em 1em;
                                height: calc(100% - 0.4em);
                                width: 100%;
                                background-color: $primary;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                gap: 0.5em;
                                transition: $transition-base;

                                & .title {
                                    font-size: 2.5em;
                                    font-weight: 500;
                                    color: $white  !important;
                                    letter-spacing: 0em;
                                    transition: $transition-base;
                                }

                                & .subtitle {
                                    font-size: 1.2em;
                                    font-weight: 300;
                                    transition: $transition-base;
                                }

                                & .divider {
                                    height: 0.08em;
                                    width: 4em;
                                    background-color: $white;
                                    transition: $transition-base;
                                }

                                & .tab-content {
                                    font-size: 0.7em;
                                    letter-spacing: 0em;
                                    transition: $transition-base;
                                }
                            }

                            &.active-tab {
                                & .tab-inner {
                                    background-color: $white;

                                    & .title {
                                        color: $black  !important;
                                    }

                                    & .subtitle {
                                        color: $primary;
                                    }

                                    & .divider {
                                        background-color: $primary;
                                    }

                                    & .tab-content {
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }

                    & .content {
                        height: 100%;
                        flex-grow: 1;
                        background-color: $default-background;
                        overflow-y: scroll;
                        padding: 1em 2em;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 1em;

                        & .title {
                            font-size: 1.2em;
                        }

                        & .content-list {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 2em;

                            & li {
                                list-style-type: disc;
                            }
                        }
                    }
                }

                & .action-button {
                    margin-top: 1.5em;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & button {
                        border-radius: 0;
                        padding: 0.5em 2.5em;

                        & span {
                            font-size: 1.5em;
                            font-weight: 800;
                        }
                    }
                }
            }
        }
    }

    // Menu-card-tab-content ends
}

// Menu-card ends