.hollow-button {
    font-size: 1em;
    background-color: rgba($white, 0);

    @supports (backdrop-filter: blur($default-blur)) {
       background-color: rgba($color: $white, $alpha: $opacity-very-less);
    }

    padding: 0.5em 2em;
    border: 0;
    border-radius: 0%;
    stroke: none;
    outline: $lite-stroke solid $primary;
    transition: $transition-base;
    font-weight: 500;

    & span {
        color: $primary;
        font-size: 1em;
        transition: $transition-base;
    }

    &:hover {
        outline: $lite-stroke solid $primary;
        background-color: $primary;

        & span {
            color: $white;
        }
    }

    &:focus {
        outline: 0;
        border-color: inherit;
        box-shadow: none;
        background: $primary;

        &:hover {
            & span {
                color: $white;
            }
        }
    }

    &.primary-button {
        background-color: rgba($primary, 0);

        &:hover {
            outline: $lite-stroke solid $primary;
            background-color: $primary;

            & span {
                color: $white;
            }
        }

        &:focus {
            background-color: rgba($primary, 0);

            &:hover {
                outline: $lite-stroke solid $primary;
                background-color: $primary;

                & span {
                    color: $white;
                }
            }
        }
    }

    &.black-button {
        background-color: rgba($black, 0);
        outline: $lite-stroke solid $black;

        & span {
            color: $black;
        }

        &:hover {
            background-color: $black;

            & span {
                color: $white;
            }
        }

        &:focus {
            background-color: rgba($black, 0);
            outline: $lite-stroke solid $black;

            & span {
                color: $black;
            }

            &:hover {
                background-color: $black;

                & span {
                    color: $white;
                }
            }
        }
    }

    &.gray-button {
        background-color: rgba($black, 0);
        outline: 1px solid $light-text-color;

        & span {
            color: $light-text-color;
        }

        &:hover {
            background-color: $light-text-color;

            & span {
                color: $white;
            }
        }

        &:focus {
            background-color: rgba($black, 0);
            outline: 1px solid $light-text-color;

            & span {
                color: $light-text-color;
            }

            &:hover {
                background-color: $light-text-color;

                & span {
                    color: $white;
                }
            }
        }
    }

    &.white-button {
        /* background-color: rgba($black, 0); */
        outline: 1px solid $white;
        border-radius: $button-border-radius;

        & span {
            color: $white;
        }

        &:hover {
            background-color: $white;

            & span {
                color: $primary;
            }
        }

        &:focus {
            background-color: rgba($black, 0);
            outline: 1px solid $white;

            & span {
                color: $white;
            }

            &:hover {
                background-color: $white;

                & span {
                    color: $primary;
                }
            }
        }
    }
}