.filled-button {
    font-size: 1em;
    background-color: $primary;
    border-radius: $button-border-radius;
    stroke: none;
    border: 0;
    font-weight: 500;
    transition: $transition-base;
    padding: 0.5em 1.5em;

    & span {
        color: $white;
        font-size: 1em;
        transition: $transition-base;
    }

    &:hover {
        outline: $lite-stroke solid $primary;
        background-color: $white;

        & span {
            color: $primary;
        }
    }

    &:focus {
        outline: 0;
        border-color: inherit;
        box-shadow: none;
        background: $primary;

        &:hover {
            & span {
                color: $white;
            }
        }
    }

    &.primary-button {
        background-color: $primary;

        &:hover {
            outline: $lite-stroke solid $primary;
            background-color: $white;

            & span {
                color: $primary;
            }
        }

        &:focus {
            background-color: $primary;

            &:hover {
                outline: $lite-stroke solid $primary;
                background-color: $white;

                & span {
                    color: $primary;
                }
            }
        }
    }

    &.black-button {
        background-color: $black;

        &:hover {
            outline: $lite-stroke solid $black;
            background-color: $white;

            & span {
                color: $black;
            }
        }

        &:focus {
            background-color: $black;

            &:hover {
                outline: $lite-stroke solid $black;
                background-color: $white;

                & span {
                    color: $black;
                }
            }
        }
    }

    &.white-button {
        background-color: $white;

        & span {
            color: $primary;
        }

        &:hover {
            outline: $lite-stroke solid $white;
            background-color: rgba($color: $white, $alpha: 0.3);

            & span {
                color: $white;
            }
        }

        &:focus {
            background-color: $white;

            & span {
                color: $primary;
            }

            &:hover {
                outline: $lite-stroke solid $white;
                background-color: rgba($color: $white, $alpha: 0.3);

                & span {
                    color: $white;
                }
            }
        }
    }
}