.text-button {
    font-size: 1em;
    background-color: rgba($white, 0);
    display: inline-block;
    padding: 0;
    border: 0;
    border-radius: 0%;
    stroke: none;
    transition: $transition-base;
    font-weight: 900;

    & span {
        color: $black;
        font-size: 1em;
        transition: $transition-base;
    }

    &:hover {
        transform: scale($scale-base);
        background-color: rgba($white, 0);
    }

    &:focus {
        outline: 0;
        box-shadow: none;

        &:hover {
            & span {
                transform: scale($scale-base);
                background-color: rgba($white, 0);
            }
        }
    }
}