.location-selector-container {
    & .odd-divided-section {
        &>div{
            &:first-child {
                width: 30%;
            }
            &:last-child {
                width: 70%;
            }
        }
    }

    & .divider-container {
        padding-top: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container-mb{
        display: none;
    }
    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        & .laptop-container{
            display: none;
        }
        & .container-mb{
            // font-size: 14px;
            // height: 100vh;
            display: block;
            & .tagline-part{
                display: flex;
                align-items: center;
                text-align: center;
            }
            & .country-selector-part{
                width: 100%;
                margin-top: 2em;
                height: 28em;
                margin-bottom: 2em;
                @media #{$small-mobile,$extra-small-mobile, $large-mobile, $tablet-device} {
                    height: 28.85em;
                }
                @media #{$extra-small-mobile} {
                    height: 24em;
                }
            }
            & .continent-selector-part {
                font-size: 1em;
                padding-bottom: 2em;
            }
        }
        
    }
}
