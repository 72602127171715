/* Gotham Black */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Black.otf') format('opentype');
    font-weight: 1000;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BlackItalic.otf') format('opentype');
    font-weight: 1000;
    font-style: italic;
    font-display: swap;
}


/* Gotham Bold */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BoldItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


/* Gotham Medium */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Medium.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-MediumItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}


/* Gotham Normal */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Book.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BookItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


/* Gotham Lite */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


/* Gotham Thin */

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}