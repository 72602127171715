.funfact-spread-columns-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10em;
    margin-top: -1.3em;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
        flex-direction: column;
        padding-top: 2.64em;
        gap: 3em;
        padding-bottom: 3em;
    }
}
