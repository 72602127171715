.category-of-people-tabs-carousel-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3em;
    @media #{$large-mobile,$small-mobile,$extra-small-mobile,$tablet-device} {
        gap: 1.6em;
        font-size: 14px;
        padding: 2.64em 0em;
    }
    
    & .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    & .tabs-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        gap: 1em;
        
        & .tabs-section {
            
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            
            & .each-tab {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    & .tab-name{
                        font-size: 1.2em;
                    }
                }

                & * {
                    pointer-events: none;
                }

                & .marker-holder {
                    height: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    & .marker {
                        position: absolute;
                        height: $thicker-divider;
                        width: 0%;
                        background-color: $primary;
                        transform: translateY(calc(1em + $thicker-divider/2));
                        transition: width $transition-specific;
                        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                            transform: translateY(calc(1em + $thicker-divider/2));
                        }

                        &.active {
                            width: 100%;
                        }
                    }
                }
            }
        }

        & .tabs-divider {
            width: 100%;
            height: $thicker-divider;
            background-color: rgba($color: $primary, $alpha: 0.2);
        }
    }

    & .cards-carousel-container {
        margin: 3em 0;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            margin: 1em;
        }
    }

}