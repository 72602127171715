.filter-download-image-card {
    position: relative;
    & .filter-btn-holder{
        display: none;
        @media #{$large-mobile} {
            @media #{$small-mobile} {
                font-size: 12px;
            }
            @media #{$extra-small-mobile} {
                font-size: 10px;
            }
            display: block;
            padding: 4em 0em;
            position: absolute;
            height: 100%;
            width: 0%;
            & .filter-btn{
                cursor: pointer;
                font-size: 1em;
                font-weight: 1000;
                background-color: #E4E4E4;
                float: left;
                padding: 0.3em 3em 0.5em 3em;
                border-bottom-left-radius: 2em;
                border-bottom-right-radius: 2em;
                position: sticky;
                scroll-behavior: smooth;
                top: 20%;
                transform: rotate(-0.25turn) translate(-0%,-150%);
            }
        }
        @media #{$tablet-device} {
            display: block;
            padding: 4em 0em;
            position: absolute;
            height: 100%;
            width: 0%;
            & .filter-btn{
                font-size: 1em;
                font-weight: 1000;
                background-color: #E4E4E4;
                float: left;
                padding: 0.3em 3em 0.5em 3em;
                border-bottom-left-radius: 2em;
                border-bottom-right-radius: 2em;
                position: sticky;
                scroll-behavior: smooth;
                top: 20%;
                transform: rotate(-0.25turn) translate(-0%,-150%);
            }
        }
    }
    .filter {
        width: 30%;
    }

    .gallery-container {
        width: 70%;

        .grid {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 90%;

            @media all {
                grid-auto-flow: initial;
                grid-template-columns: repeat(auto-fit, minmax(auto, 18em));
                justify-content: space-between;
            }

            @media #{$tablet-device} {
                justify-content: space-evenly;
            }
            @media #{$large-mobile} {
                justify-content: center;
                grid-template-columns: none;
                padding-top:4em;
            }

            @media #{$small-mobile, $extra-small-mobile} {
                justify-content: center;
                grid-template-columns: none;
                padding-top:4em;
            }

            @media #{$extra-small-mobile} {
                justify-content: center;

            }

            & .image-parts {
                @media #{$large-mobile, $extra-small-mobile,$small-mobile} {
                    width : 100%;
                }
            }

            & .grid-figure {
                display: grid;
                max-width: 18em;
                margin-bottom: 4em;
                cursor: pointer;
                transition: $transition-base;
                border-radius: 1.3em;

                @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                    max-width: 100%;
                }

                &:hover {
                    box-shadow: $default-shadow;
                }

                & .wrap-shadow {
                    position: relative;

                    & .grid-figure-shadow {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;

                        height: 15em;
                        background: radial-gradient(circle, rgba(181, 181, 181, 0) 23%, rgba(44, 44, 44, 0.39289219105610995) 80%);
                        border-radius: 1.3em;

                        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                            height: 17.35em;
                        }

                        & .download-btn {
                            width: 1.7em;
                            // border: 2px solid green;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            margin: 1em 1.7em;
                            cursor: pointer;
                            & .download-btn-cover{
                                position: absolute;
                                inset: 0;
                                z-index: 2;
                            }
                            & svg{
                                z-index: 1;
                                width: 100%;
                            }
                        }

                        & .grid-figure-crown {
                            position: absolute;
                            bottom: 100%;
                            left: 8%;
                            background-color: transparent;
                            display: flex;
                            flex-direction: column-reverse;

                            & svg {
                                height: 1.3em;
                                fill: $primary;
                            }
                        }
                    }
                }

                & .image-container {
                    & img {
                        width: 100%;
                        height: 15em;
                        border-radius: 1.3em;
                        object-fit: cover;

                        @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                            height:17.35em;
                        }
                    }

                }

                & .content {
                    padding: 1em 1.3em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 0.5em;
                    
                    & .grid-figure-title {
                        line-height: 1;
                        font-weight: bold;
                        color: black;

                        @media #{$large-mobile, $tablet-device,$small-mobile, $extra-small-mobile}{
                            font-size: 1.42em;
                        }
                    }

                    & .grid-figure-subtitle {
                        line-height: 1;
                    }
                }
            }
        }

        & .button-part {
            display: flex;
            justify-content: center;
            font-size: 1.25em;
        }
    }

    @media #{$tablet-device} {
        .filter {
            width: 0%;
            display: none;
        }

        .gallery-container {
            width: 100%;
        }

        .filter-container {
        }
    }

    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        .filter {
            width: 0%;
            display: none;
        }

        .gallery-container {
            width: 100%;
            height: max-content;
        }

        .filter-container {
        }
    }


    & .filter-container {
        margin-right: 5.5em;
        background-color: $default-background;
        padding: 2em;
        border-radius: 1.7em;
        position: sticky;
        scroll-behavior: smooth;
        top: 10%;

        & .filter-title {
            font-size: 1.5em;
            font-weight: bold;
        }

        & .filter-type {
            border: 0.5px solid $light-text-color;
            padding: 0.5em 0.7em;
            width: 100%;
            border-radius: 0.675em;
            margin-top: 1.5em;
            outline: none;
        }

        & :focus {
            border: 0.5px solid $primary;
            outline: none;
        }

        & .filter-free-resource {
            margin-left: 0.7em;
            margin-top: 1.5em;
        }

        & .filter-checkbox {
            width: 1.33em;
            height: 1.33em;
            accent-color: #E18F3C;
            margin-right: 0.5em;
        }

        & .filter-sort {
            margin-top: 1.5em;

            & .filter-header {
                border-bottom: 0.5px solid $light-text-color;
                padding-bottom: 0.5em;
            }

            & .filter-sort-options {
                margin-left: 0.7em;
                margin-top: 1em;

                // border: 2px solid red;
                div {
                    // border: 2px solid red;
                    margin-top: 0.6em;
                }
            }
        }

        & .filter-budget {
            & .filter-budget-title {
                margin-top: 2em;
                font-weight: bold;
            }

            & .filter-range-slider {
                position: relative;
                width: 100%;
                top: 0%;
                left: 0%;
                margin-top: 1.75em;

                & input[type="range"] {
                    appearance: none;
                    width: 100%;
                    outline: none;
                    // border: 0.5px solid red;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    background-color: transparent;
                    pointer-events: none;
                }

                & .slider-track {
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    border-radius: 5px;
                }

                & input[type="range"]::-webkit-slider-runnable-track {
                    -webkit-appearance: none;
                    height: 3px;
                    background-color: $primary;
                    border-radius: 5px;
                }

                & input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 16px;
                    width: 16px;
                    background-color: $primary;
                    cursor: pointer;
                    margin-top: -7px;
                    pointer-events: auto;
                    border-radius: 50%;
                }
            }

            & .filter-range-data {
                margin-top: 3.5em;
                font-size: 0.9em;

                & input {
                    width: 40%;
                    background-color: white;
                    padding: 0.4em 0.75em;
                    border: 0.5;
                    border-radius: 0.7em;
                    color: $primary;
                    border: 0.5px solid $light-text-color;
                }

                & div {
                    color: $light-text-color;
                    margin: auto;
                }
            }

            & .filter-button {
                margin-top: 1.5em;
            }
        }

    }

    & .filter-text {
        font-size: 1em;
        color: $light-text-color;
        line-height: 1;
    }

    
    .filter-modal-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        bottom: 0;
        width: 100vw;
        margin: auto;
        overflow-y: scroll;
    
        display: flex;
        justify-content: center;
        // align-items: center;
        & #modal-bg {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
    
            /* slightly transparent fallback */
            background-color: rgba(255, 255, 255, .9);
            /* if backdrop support: very transparent and blurred */
            // @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            //     background-color: rgba(255, 255, 255, .3);
            //     -webkit-backdrop-filter: blur(0.8em);
            //     backdrop-filter: blur(0.8em);
            // }
        }
        & .container{
            font-size: 14px;
            & .filter-container{
                margin: auto;
                margin-right: 0;
                // height: 80%;
            }
        }
    
    }
    .filter-modal-container::-webkit-scrollbar {
        display: none;
    }
}