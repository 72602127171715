.parallax-image-component {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    & .background-image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        & img {
            object-fit: cover;
            height: 100vh;
            width: 100%;
        }
    }

}