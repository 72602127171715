.sidestrip-container {
    width: 100vw;
    position: fixed;
    z-index: 4;
    left: 0;
    right: 0;
    transform: translateX(200%);
    transition: $transition-base;
& .close {
    width: 1.71em;
    height: 1.71em;
    display: none;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        display: block;
    }
}
    @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
        transform: translateX(0);
        // top: 5.6em !important;
        transform: translateY(100%);
    }
    @media #{ $tablet-device} {
        transform: translateX(0);
        // top: 80px !important;
        transform: translateY(100%);
    }

    &.open {
        transform: translateY(0%);
    }

    display: flex;
    justify-content: center;
    align-items: center;

    & .other-part {
        width: 100%;
        height: 100%;
    }

    & .sidestrip-wrapper {
        height: 100%;
        width: 20em;
        flex-shrink: 0;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: $default-shadow;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            width: 100%;
        }

        & .cover-image {
            height: 12em;
            width: 100%;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
                height: 13.71em;
            }
            // @media #{$small-mobile} {
            //     height: 11.71em;
            // }
            @media #{ $tablet-device} {
                height: 20.71em;
            }

            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        & .content-part {
            height: 100%;
            padding: 0em 2em;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            @media #{$large-mobile, $tablet-device} {
                padding: 1.42em 2.64em;
                justify-content: flex-start;
                gap: 1.78em;
            }
            @media #{$small-mobile,$extra-small-mobile} {
                padding: 1.42em 2.64em;
                justify-content: flex-start;
                gap: 1em;
            }
            & .bookish-card {
               
                & .title {
                    line-height: 1.2em;
                    font-size: 0.7em;

                    @media #{$large-mobile, $tablet-device} {
                        font-size: 0.85em;
                    }
                    @media #{$small-mobile,$extra-small-mobile} {
                        font-size: 1em;
                    }
                }
                
                & .subtitle {
                    font-size: 2em;
                    line-height: 1.2em;

                    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                        font-size: 2.14em;
                    }
                    // @media #{$small-mobile} {
                    //     font-size: 1.66em;
                    // }
                }
                & .content {
                    line-height: 1.2em;
                    font-size: 0.65em;

                    @media #{$small-mobile,$extra-small-mobile}{
                        font-size: 1em;
                    }
                }
            }

            & .buttons-part {
                font-size: 0.7em;
                height: 100%;
                width: 100%;
                height: max-content;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                gap: 1em;

                @media #{$large-mobile, $tablet-device} {
                    font-size: 1.42em;
                    // gap: 0.5em;
                }
                @media #{$small-mobile,$extra-small-mobile} {
                    font-size: 1.42em;
                    gap: 0.5em;
                }

                & .filled-button {
                    padding:0.3em 1.5em;
                }
                // @media #{$small-mobile} {
                //     gap: 0.5em;
                //     font-size: 1em;
                // }

                & .filled-button {
                    width: 100%;

                    & span {
                        color: $black;

                        & .bold {
                            font-weight: 900;
                        }
                    }

                    &:hover {
                        & span {
                            color: $primary
                        }
                    }
                }
            }
        }
    }
}