.loading-page-wrapper {
    padding-top: $header-height;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;

    & .brand {
        & svg {
            height: 3em;
        }
    }

    .loading-bar {
        width: 8em;
        height: 0.15em;
        background-color: rgba($color: $light-text-color, $alpha: 0.125);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(0.5em);

        &::after {
            content: '';
            width: 4em;
            height: 0.15em;
            position: absolute;
            background-color: $primary;
            animation: loop 2s ease infinite;
        }
    }


    @keyframes loop {

        0%,
        100% {
            transform: translateX(-4em);
        }

        50% {
            transform: translateX(4em)
        }
    }

    @media #{$extraBig-device} {
        font-size: 1.3;
    }
    @media #{$laptop-device} {
        font-size: 0.9em;
    }
    @media #{$desktop-device} {
        font-size: 0.7em;
    }
}