.live-button {
    font-size: 1em;
    background-color: $red;
    border-radius: $button-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.9em;
    line-height: 1em;
    stroke: none;
    border: 0;
    transition: $transition-base;
    & .dot {
        height: 0.7em;
        width: 0.7em;
        border-radius: 50%;
        background-color: $white;
        transition: $transition-base;
    }
    & span {
        color: $white;
        transition: $transition-base;
    }
    &:hover {
        outline: $lite-stroke solid $red !important;
        background-color: $white;
        & .dot {
            background-color: $red;
        }
        & span {
            color: $red;
        }
    }
    &:focus {
        outline: 0;
        border-color: inherit;
        box-shadow: none;
        background: $red;
        &:hover {
            & .dot {
                background-color: $white;
            }
            & span {
                color: $white;
            }
        }
    }
}