.header {
    // @media #{$extra-small-mobile} {
    //     font-size: 0.9em;
    // }
    height: $header-height;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    background-color: $white;

    /* @supports (backdrop-filter: blur($default-blur)) {
        background-color: rgba($color: $white, $alpha: $opacity-very-high);
        backdrop-filter: blur($default-blur);
    } */

    box-shadow: $default-shadow;
    transition: height $transition-specific;


    & .container {
        & .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    & .header-left,
    & .header-right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3em;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
            gap:1.125em;
        }

        & .join-button {
            height: $header-button-height;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 900;
        }

        & .live-button {
            padding: 0.5em 1em;
            font-weight: 900;
        }

        & .hamburger-menu {
            padding: 0;
            justify-self: flex-end;
            & svg {
                height: 1.3em;
            }
        }
    }

    & .logo {
        & svg {
            height: 2em;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                height: 1.75em;
                width:8.0625em;
            }
        }
    }

    &.shrinked-header {
        height: $shrinked-header-height;

        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
            height: 5.6em;
        }
    }
}