// Responsive Variables
/* $extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$tablet-landscape: "only screen and (max-height: 600px)";
$large-mobile: "only screen and (max-width: 767px)";
$large-mobile-shallow: "only screen and (max-width: 767px) and (max-height: 700px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)"; */

$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1600px)";
$desktop-device: "only screen and (min-width: 991px) and (max-width: 1200px)";
$tablet-device: "only screen and (min-width: 767px) and (max-width: 991px)";
$large-mobile: "only screen and (min-width: 377px) and (max-width: 767px)";
$small-mobile: "only screen and (min-width: 320px) and (max-width: 377px)";
$extra-small-mobile: "only screen and (max-width: 320px)";

// Colors
$white: #FFF;
$black: #000;
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #FF0000 !default;
$orange: #fd7e14 !default;
$orange-red: #F74B1C !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// Named Colors
$primary: #E18F3C !default;
$primary-color: #E18F3C !default;
$dull-black: #111111 !default;
$default-black: #1E1E1E !default;
$complementary: #F08A32 !default;
$secondary: #0077ac !default;
$success: #62D319 !default;
$default-background: #F6F6F6 !default;
$body-color: $default-background  !default;
$info: $cyan  !default;
$warning: #F08E1C !default;
$danger: $red  !default;
$dark: #090909 !default;
$twitter: #00ccff !default;
$behance: #0066ff !default;
$facebook: #1674EA !default;
$linkedin: #0173AF !default;
$light-text-color: #707070 !default;
$very-light-text-color: #F5F5F5 !default;
$color-overlay: #D16920;
//Spacers
/* $spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.3125,
    2: $spacer * 0.625,
    3: $spacer * 0.9375,
    4: $spacer * 1.25,
    5: $spacer * 1.625,
    6: $spacer * 1.875,
    7: $spacer * 2.1875,
    8: $spacer * 2.5,
    9: $spacer * 2.8125,
    10: $spacer * 3.125,
);
// Break-points
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
    xxl: 1200px,
); */
$grid-gutter-width: 30px;
// Transitions
$transition-base: all 600ms ease 0s;
$transition-base-with-delay: all 600ms ease 100ms;
$transition-specific: 600ms ease 0s;
// Fonts 
$font-family-base: 'Gotham', sans-serif;
$font-wight-base: 500;
// Scrollbar
$scrollbar-width: 0.5em;
// default scaling
$scale-base: 1.05;
$scale-high: 1.2;
// height of header
$header-height: 5em;
$shrinked-header-height: 4em;

// height of navigation
$navigator-height: 8em;

$banner-tab-container-height: 8em;
//default stroke and borders
$thin-stroke: 1px;
$medium-thin-stroke: 1.5px;
$lite-stroke: 2px;
$normal-stroke: 3px;
$medium-stroke: 5px;
$bold-stroke: 8px;
$ultra-bold-stroke: 10px;
$thin-border: $thin-stroke;
$medium-thin-border: $medium-thin-stroke;
$lite-border: $lite-stroke;
$normal-border: $normal-stroke;
$medium-border: $medium-stroke;
$bold-border: $bold-stroke;
$ultra-bold-border: $ultra-bold-stroke;
$image-border-outline: 0.6em;


//Border Radius
$button-border-radius: 2em;
// Page Wrapper
$page-wrapper-padding: 6em;
// Header Icon Height
$header-icon-height: 1.2em;
// Header Button Height
$header-button-height: 2em;
$container-gap: 4em;
$section-padding: $container-gap 0em;
$primary-haze: rgba($primary, 0.6);
// Divider
$thicker-divider: 0.2em;
$thick-divider: 0.08em;
$thin-divider: 0.08em;

$normal-divider-length: 10em;
// base opacity
$opacity-base: 0.6;
// Default Shadows
$lower-transparent-black: rgba($color: $black, $alpha: $opacity-base);
$default-shadow: 0 0 10px 0px rgba($black, 0.2);
$deep-shadow: 0 0 10px 0px rgba($black, 0.4);

// image border radius
$image-border-radius: 0.8em;


// base opacity
$opacity-base: 0.6;
$opacity-less: 0.4;
$opacity-very-less: 0.1;
$opacity-high: 0.8;
$opacity-very-high: 0.95;
$opacity-full: 1;

// Blur Variables
$default-blur: 6px;

// Brightness Variables
$default-low-brightness: 0.65;