.banner-sitemap-container {
    height: calc(100vh - $header-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device}{
        height: auto;
    }
    & .banner-background {
        height: calc(100vh - $banner-tab-container-height - $header-height);
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;
        @media #{$large-mobile,$small-mobile, $tablet-device}{
            height: 29.14em;
        }
        & img {
            width: 100%;
            height: 100%;
            // @media #{$large-mobile, $tablet-device} {
            //     // height: calc(100vh - $banner-tab-container-height - $header-height);
            //     height: 24.14em;
            // }
            object-fit: cover;
        }
    }
    
    & .title-tabs-part {
        width: 100%;
        background-color: $white;
        & .page-title-container {
            height: 0;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
            @media #{$large-mobile, $tablet-device} {
                font-size: 10px;
            }
            @media #{$small-mobile, $extra-small-mobile} {
                font-size: 8px;
            }
            & .page-title {
                position: absolute;
                color: $white;
                display: inline-block;
                font-size: 5.5em;
                font-weight: 1000;
                line-height: 1em;
                transform: translateY(-0.355em);
                text-transform: uppercase;
            }
        }
        
        & .tabs-container {
            width: 100%;
            height: $banner-tab-container-height;
            display: flex;
            @media #{$extraBig-device} {
                justify-content: space-around;
            }
            @media #{$laptop-device} {
                justify-content: space-around;
            }
            @media #{$desktop-device} {
                justify-content: space-around;
            }
            @media #{$tablet-device} {
                justify-content: space-around;
            }
            align-items: center;
            @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
                display: grid;
                grid-auto-flow: column;
                overflow-x: auto;
                gap: 4em;
                height: auto;
                padding-top: 2.5em;
                padding-bottom: 3.21em;
            }
            @media #{$tablet-device} {
                display: grid;
                grid-auto-flow: column;
                overflow-x: auto;
                gap: 4em;
                height: auto;
                padding-top: 2.5em;
                padding-bottom: 3.21em;
                padding-left:18em;
            }
            & .each-tab{
                @media #{$large-mobile,$small-mobile, $extra-small-mobile, $tablet-device} {
                    width: max-content;
                    font-size: 1.42em;
                }
                font-size: 1.125em;
                font-weight: 1000;
                text-transform: uppercase;
                color: $light-text-color;
                transition: $transition-base;
                cursor: pointer;
                & span {
                    pointer-events: none;
                }
                &:hover {
                    color: rgba($color: $black, $alpha: 0.75);
                }
                &.active-tab {
                    color: $black;
                    transform: scale(1.1);
                }
            }
        }
        ::-webkit-scrollbar{
            display: none;
        }

    }
    @media #{$large-mobile, $small-mobile, $extra-small-mobile, $tablet-device} {
        & .title-tabs-part {
            & .page-title {
                bottom: 0;
                transform: translateY(0.15em) !important;
                text-align: right;
            }
        }
    }

}