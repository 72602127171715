.map-component {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & svg {
        height: 100%;
        width: 100%;
    }

    & .country {
        &:not(.has-office) {
            fill: rgba($color: $light-text-color, $alpha: 0.05);
        }

        stroke: rgba($color: $light-text-color, $alpha: 0.4);
        stroke-width: 0.02em;
        pointer-events: none;

        &.in-active-continent {
            pointer-events: all !important;

            &:not(.has-office) {
                fill: rgba($color: $light-text-color, $alpha: 0.2);
                stroke: rgba($color: $light-text-color, $alpha: 0.8);
            }

            stroke-width: 0.033em;
            transition: fill $transition-specific;
            cursor: pointer;

            &:hover {
                fill: $black;
                stroke: $black;
            }
        }

        &.selected {
            fill: $primary  !important;
            stroke: rgba($color: $light-text-color, $alpha: 0.8) !important;
            cursor: default !important;
        }
    }

    & #resize-icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        cursor: pointer;
        height: 1.3em;
        width: 1.3em;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            fill: rgba($color: $light-text-color, $alpha: 0.8);
            transition: $transition-base;
        }

        &:hover {
            & svg {
                fill: $black;
            }
        }
    }

    & .map-tooltip {
        position: absolute;
        display: none;
        z-index: 1;
        margin: auto;
        padding: 0 1em;
        border-radius: $button-border-radius;
        border: $thin-border solid $primary;
        background-color: $white;
        box-shadow: $default-shadow;
        text-align: center;

        & span {
            font-size: 0.8em;
            line-height: 0.8em;
            font-weight: 700;
            color: $primary;
            white-space: nowrap;
        }

        & .office-name-address {
            padding: 1em 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5em;

            & .office-name {
                font-size: 1em;
                font-weight: 900;
            }

            & .office-address {
                font-size: 0.7em;
                font-weight: 500;
            }
        }
    }
}