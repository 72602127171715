.imageoverimage-card-container {
    // @media #{$large-mobile, $tablet-device}{
    //     padding: 0;
    // }

    @media #{$large-mobile,$small-mobile,$extra-small-mobile}{
        padding: 0em
    }
    & .card-part {
        @media #{$large-mobile,$small-mobile,$extra-small-mobile}{
            padding: 1.92em 2.64em;
        }
        @media #{$tablet-device}{
            padding: 1.92em 0em;
        }
        & .black-button {
            font-size: 0.9em;
            margin-top: 1em;
            font-weight: 300;
            letter-spacing: 0.02em;
        }
    }
}