.frequently-asked-question-section {
    padding: 0em 4em;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
        padding: 0;
    }
    & .que-ans {
        padding: 1em;
        @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
            padding-left: 0;
        }
        & .question {
            color: $primary;
            font-weight: 900;
            font-size: 1.5em;
            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                font-size:1em;
            }
        }

        & .answer {
            width: 60%;
            font-size: 0.8em;
            color:$light-text-color;

            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                width:100%;
            }
        }
        
        & .time {
            margin-top: 1.5em;
            font-size: 0.6em;
            color:$light-text-color
        }
    }

    & .divider {
        border: 1px solid $default-background;
    }
}