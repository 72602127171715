.jobs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4em;
    transition: height $transition-specific;

    @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
        gap:0;
    }
}