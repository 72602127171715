.image-card-container {
    font-size: 1em;
    @media #{$large-mobile,$small-mobile,$extra-small-mobile} {
        // font-size: 14px;
        // padding: 0;
        padding-top: 2.85em;
        & .half-devided-section{
            display: flex;
            flex-direction: column;
            & .image-part{
                width: 100%;
            }
            & .card-part {
                width: 100%;
                padding: 2.85em 0em;
                padding-bottom: 0;
                
            }
        }
    }
    @media #{$tablet-device} {
        font-size: 16px;
        // padding: 0;
        padding-top: 2.85em;
        & .half-devided-section{
            display: flex;
            flex-direction: column;
            & .image-part{
                width: 100%;
            }
            & .card-part {
                width: 100%;
                padding: 2.85em 0em;
                padding-bottom: 0;
                
            }
        }
    }
    & .half-devided-section{
        & .image-part {
            flex-shrink: 0;
    
            &>div {
                border-radius: $image-border-radius;

                @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device} {
                    border-radius: 1.57em;
                }
            }
    
            & .image-box {
                height: 100%;
                padding-top: 80.7%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                & .image-holder {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: $image-border-radius;
                    overflow: hidden;
    
                    & img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
    
        }
    }
}