.center-aligned-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    padding-top: 3em;
    @media #{$large-mobile,$small-mobile, $extra-small-mobile} {
        padding: 0em;
        padding-top: 3.21em;
        padding-bottom: 2.64em;
    }
    @media #{$tablet-device} {
        padding: 2.3125em;
        padding-top: 67px;
        padding-bottom: 0;
    }

    & .title {
        // font-size: 1em;
        color: #707070;
        letter-spacing: 0.4em;
        font-weight: 600;
    }

    & .subtitle {
        font-size: 3em;
        font-weight: 900;
        line-height: 1.3em;
        letter-spacing: 0.02em;

        @media #{$large-mobile, $tablet-device, $small-mobile, $extra-small-mobile} {
            font-size: 2.1428em;
        }
    }
    & .divider {
        height: $thick-divider;
        width: 10em;
        background-color: $light-text-color;

        &.primary {
            background-color: $primary;
        }
    }
    & .content {
        max-width: 60%;

        @media #{$large-mobile, $extra-small-mobile, $small-mobile} {
            max-width: 100%;
        }
        @media #{$tablet-device} {
            max-width: 100%;
        }
    }
}

.center-aligned-card-sm {
    @media #{$large-mobile, $extra-small-mobile,$small-mobile} {
        padding: 0;
        padding-top: 3.21em;
        padding-bottom: 0;
    }
}
