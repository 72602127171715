.home-our-symbol-section {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    & .heading-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;

        & .subtitle {
            @media #{$large-mobile,$small-mobile,$extra-small-mobile, $tablet-device}{
                text-align: center;
            }
        }
    }

    & .divider {
        height: $thicker-divider;
        width: 18em;
        background-color: $primary;
    }
    & .pricing-part {
        width: 100%
    }
    /* & .action-button {
        margin-top: 1em;
    } */
}